const InquiryStatusIcon = ({ inquiryStatus, isMobile = false }) => {
    const statusData = {
        1: { text: 'در انتظار پردازش', color: 'var(--waiting-color)' },
        2: { text: 'در حال بررسی', color: 'var(--processing-color)' },
        3: { text: 'بررسی شده', color: 'var(--primary-light-color)' },
        4: { text: 'خاتمه یافته', color: 'var(--completed-color)' },
        5: { text: 'لغو شده', color: 'var(--cancelled-color)' },
        6: { text: 'مرجوع شده', color: 'var(--returned-color)' }
    };

    const { text, color } = statusData[inquiryStatus] || { text: 'نامشخص', color: 'black' };

    return (
        <div style={{
            borderRadius: '5px', backgroundColor: color, display: 'flex',
            padding: '2px 5px 2px 5px'
        }}>
            <span style={{
                fontFamily: 'IRANSans, serif', color: 'white',
                fontWeight: 'bold', fontSize: isMobile ? '3vw' : '0.9vw', alignSelf: 'center'
            }}>
                {text}
            </span>
        </div>
    );
};

export default InquiryStatusIcon;