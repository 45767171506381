import React, {useState} from 'react';
import styles from "../../../styles/auditor/desktop/SelectProductModal.module.css";
import {searchProducts} from "../../../APIs/product";
import CreatableSelect from "react-select/creatable";
import {creatableSelectCustomStyles} from "../../common/utils";

const ProductSearch = ({onProductSelected}) => {
    const [searchOptions, setSearchOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const fetchProducts = async () => {
        setIsLoading(true);
        try {
            const products = await searchProducts();
            const options = products.data.products.map(product => ({
                value: product.id,
                label: formatProductLabel(product),
                product: product
            }));
            setSearchOptions(options);
        } catch (error) {
            console.error('Error searching products:', error);
            setSearchOptions([]);
        } finally {
            setIsLoading(false);
        }
    }

    const formatProductLabel = (product) => {
        const attributeString = Object.entries(product.attributes)
            .map(([name, value]) => `${value}`)
            .join(' | ');
        return `${product.name} | ${product.main_category} | ${product.sub_category} | ${product.brand}${attributeString ? ' | ' + attributeString : ''}`;
    };

    const handleProductSelect = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            onProductSelected(selectedOption.product);
        }
    };

    function renderCreatableSelect(name, value, onChange, onFocus, isLoading, options, placeholder, index, className = styles.ChooseProductModalDropdown) {
        return (
            <CreatableSelect
                isClearable
                isSearchable
                value={value}
                onFocus={onFocus}
                isLoading={isLoading}
                loadingMessage={() => "در حال جستجو ..."}
                options={options}
                onChange={onChange}
                className={className}
                placeholder={placeholder}
                noOptionsMessage={() => "گزینه‌ای وجود ندارد"}
                isValidNewOption={() => false}
                styles={creatableSelectCustomStyles}
            />
        );
    }

    return (
        <div className={styles.SearchContainer}>
            {renderCreatableSelect(
                'productSearch',
                selectedOption,
                handleProductSelect,
                fetchProducts,
                isLoading,
                searchOptions,
                "جستجوی کالا...",
                0,
                styles.SearchSelect
            )}
        </div>
    );
};

export default ProductSearch;