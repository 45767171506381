import {useState} from 'react';
import {FaCheck} from "@react-icons/all-files/fa/FaCheck";
import {FaStar} from "@react-icons/all-files/fa/FaStar";
import {MdModeEdit} from "@react-icons/all-files/md/MdModeEdit";
import {MdRemoveRedEye} from "@react-icons/all-files/md/MdRemoveRedEye";
import {IoIosArrowDown} from "@react-icons/all-files/io/IoIosArrowDown";
import {IoIosArrowUp} from "@react-icons/all-files/io/IoIosArrowUp";
import {IoMdDownload} from "@react-icons/all-files/io/IoMdDownload";
import styles from "../styles/auditor/desktop/AuditorInquiry.module.css"

const useInquiryTable = (inquiryDetail, receiptsDetail, handleSelectReceiptPhoto, handleDownload, handleExpandRow, expandedRows, isAuditor = false, handleEditReceipt = null) => {
    const [selectedReceipt, setSelectedReceipt] = useState(null);

    if (inquiryDetail === null || receiptsDetail === null) {
        return { tableHeader: [], tableRows: [] };
    }

    const generateTableHeader = () => {
        const baseHeader = [
            {value: "فروشنده"},
            {value: "نحوه پرداخت"},
            {value: "جمع کل"},
            {value: ""},
            {value: ""},
        ];

        if (isAuditor && inquiryDetail.inquiry.status === 2) {
            baseHeader.unshift({value: "انتخاب"});
        }

        return [baseHeader];
    };

    const generateTableRows = () => {
        if (receiptsDetail.length === 0) return [];

        return receiptsDetail.map((receipt, index) => {
            const isSelected = selectedReceipt !== null && selectedReceipt === receipt.id;
            const getColor = isSelected ? 'var(--primary-light-color)' : 'black';

            const findPhotoById = (id) => {
                return inquiryDetail.inquiry.photos.find(photo => photo.id === id) || null;
            };

            const mainRow = [
                {value: receipt.store.name, color: getColor, bold: true},
                {value: receipt.payment_method, color: getColor, bold: true},
                {value: `${receipt.total_price.toLocaleString()} ریال`, color: getColor, bold: true},
                {
                    value: (() => {
                        const photo = findPhotoById(receipt.photo);
                        if (!photo) return "";
                        return (
                            <div className={styles.ChoicesContainer}>
                                {photo.is_selected_by_user && (
                                    <span className={styles.myChoiceText}>
                                        <FaStar/>&nbsp;&nbsp;&nbsp;
                                        {isAuditor ? 'انتخاب مسئول خرید' : 'انتخاب من'}
                                    </span>
                                )}
                                {photo.is_selected_by_auditor && (
                                    <span className={styles.auditorChoiceText}>
                                        <FaCheck/>&nbsp;&nbsp;&nbsp;انتخاب کارشناس
                                    </span>
                                )}
                            </div>
                        );
                    })(),
                    paddingTop: "0",
                    paddingBottom: "0"
                },
                {
                    value: (
                        <div className={styles.Icons}>
                            <MdRemoveRedEye
                                onClick={() => handleSelectReceiptPhoto(receipt.photo)}
                                className={styles.ShowReceiptIcon}
                            />
                            <IoMdDownload
                                className={styles.DownloadIcon}
                                onClick={() => handleDownload(receipt.photo, index)}
                            />
                            {isAuditor && inquiryDetail.inquiry.status === 1 && (
                                <MdModeEdit
                                    onClick={() => handleEditReceipt(receipt.photo, index)}
                                    className={styles.EditIcon}
                                />
                            )}
                            {expandedRows.includes(index) ? (
                                <IoIosArrowUp
                                    onClick={() => handleExpandRow(index)}
                                    className={styles.ExpandIcon}
                                />
                            ) : (
                                <IoIosArrowDown
                                    onClick={() => handleExpandRow(index)}
                                    className={styles.ExpandIcon}
                                />
                            )}
                        </div>
                    )
                }
            ];

            // For checking a receipt as selected by the auditor
            if (isAuditor && inquiryDetail.inquiry.status === 2) {
                mainRow.unshift({
                    value: (
                        <div
                            className={styles.Circle}
                            style={{
                                backgroundColor: isSelected ? 'var(--primary-light-color)' : 'transparent'
                            }}
                            onClick={() => setSelectedReceipt(receipt.id)}
                        />
                    )
                });
            }

            return mainRow;
        });
    };

    return {
        tableHeader: generateTableHeader(),
        tableRows: generateTableRows(),
        selectedReceipt
    };
};

export default useInquiryTable;