import {useState} from 'react';
import {getPhoto} from "../APIs/inquiry";

const useFileDownload = (id) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [fileDownloadError, setFileDownloadError] = useState(null);

    const downloadFile = async (photo_id, index) => {
        setIsDownloading(true);
        setFileDownloadError(null);

        try {
            const file = await getPhoto(photo_id, id);
            if (file?.data.photo) {
                const {file_format, data_uri} = file.data.photo;
                const link = document.createElement('a');
                link.href = data_uri;
                link.download = `file_${index + 1}.${file_format === 'pdf' ? 'pdf' : 'png'}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error("Error fetching photo:", error);
            setFileDownloadError("Error downloading file");
        } finally {
            setIsDownloading(false);
        }
    };

    return {downloadFile, isDownloading, fileDownloadError};
};

export default useFileDownload;