import {useEffect, useState} from 'react';
import {Chart} from 'chart.js/auto';
import {getSpendingOverTime} from "../../../APIs/auditor_dashboard";

const useSpendingLineChart = (chartRef, chartInstanceRef, filters, setLoading) => {
    const [data, setData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    const fetchSpendingOverTime = async () => {
        try {
            setLoading(true);
            const response = await getSpendingOverTime(filters);
            setData(response.data);
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching spending over time:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            fetchSpendingOverTime();
        }
    }, [chartRef, filters]);

    useEffect(() => {
        if (dataLoaded) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
            const ctx = chartRef.current.getContext("2d");

            Chart.defaults.font.family = 'IRANSansFaNum';
            // Chart.defaults.font.size = '12.5vw';

            // background gradient color
            const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
            gradient.addColorStop(0, 'rgba(16, 135, 255, 0.7)');
            gradient.addColorStop(1, 'rgba(16, 135, 255, 0.1)');

            const labels = Object.keys(data).length ? Object.keys(data.spending) : [];
            const chartData = Object.keys(data).length ? Object.values(data.spending) : [];

            chartInstanceRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        data: chartData,
                        fill: true,
                        borderColor: '#1087FF',
                        pointBackgroundColor: '#1087FF',
                        pointHoverBackgroundColor: '#1A237E',
                        pointRadius: 2,
                        pointHoverRadius: 3,
                        borderWidth: 1,
                        backgroundColor: gradient,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            ticks: {
                                maxRotation: 90,
                                minRotation: 0,
                            },
                            grid: {
                                display: true
                            }
                        },
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#1A237E',
                            titleColor: 'white',
                            bodyColor: 'white',
                            displayColors: false,
                            intersect: false,
                            callbacks: {
                                label: function (tooltipItem) {
                                    return `مقدار: ${tooltipItem.formattedValue}`;
                                }
                            }
                        }
                    },
                }
            });
            setDataLoaded(false);
        }
    }, [data, dataLoaded, chartRef, chartInstanceRef]);
};

export default useSpendingLineChart;
