import styles from "../../../styles/user/mobile/UserHeader.module.css";
import { FaRegBell } from "@react-icons/all-files/fa/FaRegBell";
import Navbar from "../../../components/Menu/MobileNavbar";
import { FiMenu } from "@react-icons/all-files/fi/FiMenu";
import { FiX } from "@react-icons/all-files/fi/FiX";
import React, { useEffect, useState } from "react";
import NotificationSection from "../../../components/NotificationSection/NotificationSection";


import { getInquiryEventsCount } from "../../../APIs/inquiry";

const UserHeaderMobile = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    const [inquiryEventsCount, setInquiryEventsCount] = useState(0);

    useEffect(() => {
        const fetchInquiryEventsCount = async () => {
            try {
                const inquiryEventCountData = await getInquiryEventsCount();
                setInquiryEventsCount(inquiryEventCountData.data.count);
            } catch (error) {
                setInquiryEventsCount(0);
            } finally {
            }
        };

        fetchInquiryEventsCount();

    }, [isNotificationPanelOpen]);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const menuItems = [
        // { label: 'صفحه اصلی', path: '/' },
        { label: 'درخواست‌ها', path: '/user' },
        { label: 'گزارش‌ها', path: '/coming-soon' },
        { label: 'آموزش', path: '/coming-soon' },
        { label: 'خروج از حساب کاربری', path: '/logout' },
    ];

    const toggleNotificationPanel = () => {
        setIsNotificationPanelOpen(!isNotificationPanelOpen);
    };

    return (
        <div className={styles.HeaderContainer}>
            <div className={styles.InfoContainer}>
                <div className={styles.leftSection}>
                    <a href={'/'} className={styles.platformName}>ایران‌تدارکات</a>
                </div>

                <div className={styles.rightSection}>
                    {
                        isNotificationPanelOpen ?
                            <FiX className={styles.BellIcon} onClick={toggleNotificationPanel} />
                            :
                            <div className={styles.BellIconContainer}>
                                <FaRegBell className={styles.BellIcon} onClick={toggleNotificationPanel} />
                                {
                                    inquiryEventsCount !== 0 ? (
                                        inquiryEventsCount < 10 ? (
                                            <span className={styles.NotificationCount}>{inquiryEventsCount}</span>
                                        ) : (
                                            <span className={styles.NotificationCount}>۹+</span>
                                        )
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                    }
                    {isMenuOpen
                        ?
                        <FiX className={styles.menu_icon} onClick={toggleMenu} />
                        :
                        <FiMenu className={styles.menu_icon} onClick={toggleMenu} />
                    }
                </div>
            </div>
            {isMenuOpen && (
                <div className={styles.Backdrop}></div>
            )}

            {isMenuOpen &&
                <Navbar items={menuItems} />
            }

            {isNotificationPanelOpen &&
                <NotificationSection onClose={toggleNotificationPanel} device={"mobile"} />
            }
        </div>
    )
}

export default UserHeaderMobile;