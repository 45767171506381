import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressBar.module.css';

const ProgressBar = ({progress}) => {
    return (
        <div className={styles.progressContainer}>
            <div className={styles.progressBar} style={{width: `${progress}%`}}>
                {Math.round(progress)}%
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
};

export default ProgressBar;
