import React, {useState} from 'react';
import styles from "./ReturnConfirmationModal.module.css";
import AutoGrowInput from "../AutoGrowInput";

const ReturnConfirmationModal = ({onClose, onConfirm}) => {
    const [explanation, setExplanation] = useState('');

    const handleExplanationChange = (value) => {
        setExplanation(value);
    };

    const handleConfirm = () => {
        onConfirm(explanation);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>ارجاع درخواست</h2>
                <p>
                    شما ممکن است به دلایلی مانند ناخوانا بودن فاکتورها، عدم ارایه اطلاعات کافی
                    توسط کاربر و... درخواست را به کاربر ارجاع دهید. چنانچه از ارجاع درخواست
                    اطمینان دارید، لطفا دلیل ارجاع را در ادامه ذکر کنید.
                </p>
                <div className={styles.explanationSection}>
                    <h3>توضیحات کارشناس</h3>
                    <AutoGrowInput
                        value={explanation}
                        onInputChange={handleExplanationChange}
                        styleClassName={styles.inputText}
                        placeholder={""}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <button className={styles.confirmButton} onClick={handleConfirm}>ارجاع درخواست</button>
                    <button className={styles.cancelButton} onClick={onClose}>بازگشت</button>
                </div>
            </div>
        </div>
    );
};

export default ReturnConfirmationModal;