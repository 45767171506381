export const compressImage = (uri, quality, callback) => {
    const img = new Image();
    img.onload = () => {
        try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas size to the image size
            const width = img.width;
            const height = img.height;
            canvas.width = width;
            canvas.height = height;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, width, height);

            // Compress the image
            const compressedUri = canvas.toDataURL('image/jpeg', quality);

            callback(null, compressedUri);
        } catch (error) {
            callback(error, null);
        }
    };
    img.onerror = (error) => {
        callback(error, null);
    };
    img.src = uri;
};
