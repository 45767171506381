import {useNavigate, useParams} from "react-router-dom";
import styles from "../../styles/auditor/desktop/AuditorInquiry.module.css"
import React, {useEffect, useRef, useState} from "react";
import LoadingComponent from "../../components/Loading/Loading";
import convertDateToShamsi from "../../utils/functions";
import CustomTable from "../../components/CustomTable/CustomTable";
import AutoGrowInput from "../../components/AutoGrowInput";
import toast_style from "../../styles/Toastify.css";
import {ToastContainer} from "react-toastify";
import ApprovedReceiptDocument from "./ApprovedReceiptDocument";
import {useReactToPrint} from 'react-to-print';
import PDFPreview from "../../components/PDFPreview/PDFPreview";
import emptyTable from "../../assets/images/empty_table.svg"
import InquiryStatusIcon from "../../components/InquiryStatusIcon";
import {
    finalizeInquiry,
    getPhoto
} from "../../APIs/inquiry";
import TimeLine from "../../components/TimeLine/TimeLine";
import ConfirmationDialog from "../../components/Confirmation/ConfirmationDialog";
import SuccessDialog from "../../components/Success/SuccessDialog";
import useFetchInquiry from "../../hooks/useFetchInquiry";
import useFileDownload from "../../hooks/useFileDownload";
import useComments from "../../hooks/useComments";
import useInquiryTable from "../../hooks/useInquiryTable";
import InquiryProductsInfo from "../../components/InquiryProductsInfo";
import ReactLoading from "react-loading";
import {CircularProgress} from "@mui/material";


const InquiryDesktop = ({isFinancialManager = false, isManager = false}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [filePreviewLoading, setFilePreviewLoading] = useState(false);

    const [selectedReceiptPhoto, setSelectedReceiptPhoto] = useState(null);
    const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
    const [showInquiryApprovalSuccess, setShowInquiryApprovalSuccess] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const [expandedRows, setExpandedRows] = useState([]);
    const handleExpandRow = (index) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(index)
                ? prevExpandedRows.filter((row) => row !== index)
                : [...prevExpandedRows, index]
        );
    };

    // Comment
    const {
        newComment,
        timelineItems,
        handleCommentChange,
        handleAddNewComment,
        isFetchingComments,
        isSendingComment
    } = useComments(id);
    //

    // Convert to PDF
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setShowInquiryApprovalSuccess(false);
            setShowApproveConfirmation(false);
            if (showPdf) {
                window.location.reload();
            }
        },
        onPrintError: () => {
            setShowInquiryApprovalSuccess(false);
            setShowApproveConfirmation(false);
        }
    });
    //

    // Download PDF
    const {downloadFile, isDownloading} = useFileDownload(id);
    const handleDownload = (photo_id, index) => {
        downloadFile(photo_id, index);
    };
    //

    // File Preview
    async function handleSelectReceiptPhoto(photo_id) {
        setFilePreviewLoading(true);
        try {
            const photo = await getPhoto(photo_id, id);
            if (photo?.data.photo) {
                const {file_format, data_uri} = photo.data.photo;
                file_format === "pdf" ? handlePdfClick(data_uri) : setSelectedReceiptPhoto(data_uri);
            }
        } catch (error) {
            console.error("Error fetching photo:", error);
        } finally {
            setFilePreviewLoading(false);
        }
    }

    const [selectedPdf, setSelectedPdf] = useState(null);
    const handlePdfClick = (pdfUrl) => {
        setSelectedPdf(pdfUrl);
    };
    //

    // Fetch Inquiry
    const {
        inquiryLoading,
        receiptsLoading,
        inquiryDetail,
        receiptsDetail,
        selectedProducts,
        expansionData,
        fetchAll
    } = useFetchInquiry(id);

    useEffect(() => {
        fetchAll();
    }, []);
    //

    // Inquiry Table
    const {
        tableHeader,
        tableRows
    } = useInquiryTable(
        inquiryDetail,
        receiptsDetail,
        handleSelectReceiptPhoto,
        handleDownload,
        handleExpandRow,
        expandedRows
    );
    //

    const handleFinalizeInquiry = async () => {
        try {
            // setLoading(true);
            await finalizeInquiry(id);
            setShowPdf(true);
        } catch (error) {
            // 500 Error
        } finally {
            // setLoading(false);
            setShowApproveConfirmation(false);
            setShowInquiryApprovalSuccess(true);
        }
    }

    if (inquiryDetail === null || inquiryLoading) {
        return (
            <LoadingComponent isMobile={false}/>
        );
    } else if (inquiryDetail === "Error") {
        // TODO: handle this scenario
    } else {
        return (
            <div
                className={styles.InquiryDetailsContainer}>
                <div className={styles.NameAndStatusContainer}>
                    <span className={styles.InquiryName}>
                        عنوان درخواست: <strong>{inquiryDetail.inquiry.subject}</strong>
                    </span>
                    <span className={styles.InquiryName}>
                        شناسه درخواست: <strong>{inquiryDetail.inquiry.name}</strong>
                    </span>
                    <InquiryStatusIcon inquiryStatus={inquiryDetail.inquiry.status}/>
                </div>
                <div className={styles.PageContainer}>
                    <ToastContainer className={toast_style}/>
                    <div className={styles.ColumnsContainer}>
                        <div className={styles.FirstCol}>
                            <div className={styles.FirstRow}>
                                <div className={styles.InquiryDetails}>
                                    <span className={styles.AuditorInquiryPageHeader}>جزئیات درخواست</span>
                                    <div className={styles.AuditorInquiryInputField}>
                                        <span className={styles.InputText}>تاریخ ثبت در سامانه:</span>
                                        <span
                                            className={styles.InputAnswer}>{convertDateToShamsi(inquiryDetail.inquiry.submitted_date)}
                                        </span>
                                    </div>
                                    {inquiryDetail.result?.date &&
                                        <div className={styles.AuditorInquiryInputField}>
                                            <span className={styles.InputText}>تاریخ بررسی:</span>
                                            <span
                                                className={styles.InputAnswer}>{convertDateToShamsi(inquiryDetail.result.date)}
                                            </span>
                                        </div>
                                    }
                                    <div className={styles.AuditorInquiryInputField}>
                                        <span className={styles.InputText}>نام ایجاد کننده:</span>
                                        <span
                                            className={styles.InputAnswer}>{inquiryDetail.inquiry.fullname}
                                        </span>
                                    </div>
                                    <div className={styles.AuditorInquiryInputField}>
                                        <span className={styles.InputText}>نام شرکت:</span>
                                        <span
                                            className={styles.InputAnswer}>{inquiryDetail.inquiry.organization}
                                        </span>
                                    </div>

                                </div>
                                <div className={styles.Product}>
                                    <span className={styles.AuditorInquiryPageHeader}>کالاهای این درخواست</span>
                                    {receiptsLoading ?
                                        <div className={styles.loading_container}>
                                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                          width='50px'
                                                          height='50px'/>
                                        </div>
                                        :
                                        <InquiryProductsInfo selectedProducts={selectedProducts}/>
                                    }
                                </div>

                            </div>
                            <div className={styles.SecondRow}>
                                <div
                                    className={`${styles.ProcessedReceipts} ${styles.FullSize}`}>
                                    <span className={styles.AuditorInquiryPageHeader}>
                                        پیش‌فاکتور‌های پردازش شده
                                    </span>
                                    {receiptsLoading ?
                                        <div className={styles.loading_container}>
                                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                          width='50px'
                                                          height='50px'/>
                                        </div>
                                        :
                                        tableRows.length === 0 ?
                                            <div className={styles.EmptyTableContainer}>
                                                <img className={styles.EmptyTableImage} src={emptyTable}
                                                     alt="Empty Table"/>
                                                <span className={styles.ExplanationText}>
                                                    هنوز هیچ پیش‌فاکتوری پردازش نشده است.
                                                </span>
                                            </div>
                                            :
                                            <div className={styles.InquiriesTableContainer}>
                                                <CustomTable headerData={tableHeader} data={tableRows}
                                                             styles={"desktop"}
                                                             expandedRows={expandedRows} expansionData={expansionData}/>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {(!isFinancialManager || !isManager) &&
                            < div className={styles.ThirdColumn}>
                                <div className={styles.InquiryHistoryTimeLine}>
                                    <span className={styles.AuditorInquiryPageHeader}>گردش کار</span>
                                    {isFetchingComments ?
                                        <div className={styles.loading_container}>
                                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                          width='50px'
                                                          height='50px'/>
                                        </div>
                                        :
                                        <TimeLine items={timelineItems}/>
                                    }
                                </div>
                                <div className={styles.AddingCommentContainer}>
                                    <span className={styles.AuditorInquiryPageHeader}>اضافه کردن کامنت</span>
                                    <AutoGrowInput
                                        placeholder={""}
                                        value={newComment}
                                        onInputChange={handleCommentChange}
                                        styleClassName={styles.commentInputField}
                                    />
                                    <button
                                        className={styles.SendButton}
                                        onClick={handleAddNewComment}
                                        disabled={isSendingComment}
                                    >
                                        {isSendingComment ? 'لطفا صبر کنید ...' : 'ارسال'}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>

                    {(!isFinancialManager || !isManager) &&
                        inquiryDetail.inquiry.status === 3 &&
                        <div className={styles.Buttons}>
                            <button className={styles.ApproveProcessing}
                                    onClick={() => setShowApproveConfirmation(true)}>
                                تایید نهایی
                            </button>
                            <button className={styles.CompleteProcessing}
                                    onClick={() => navigate(`/user/edit-inquiry/${id}`)}>
                                ویرایش درخواست
                            </button>
                            <button className={styles.BackButton} onClick={() => navigate(`/user`)}>
                                بازگشت
                            </button>
                        </div>
                    }
                    {(!isFinancialManager || !isManager) &&
                        inquiryDetail.inquiry.status === 1 &&
                        <div className={styles.Buttons}>
                            <button className={styles.CompleteProcessing}
                                    onClick={() => navigate(`/user/edit-inquiry/${id}`)}>
                                ویرایش درخواست
                            </button>
                            <button className={styles.BackButton} onClick={() => navigate(`/user`)}>
                                بازگشت
                            </button>
                        </div>
                    }

                    {inquiryDetail.inquiry.status === 4 && (
                        <div className={styles.Buttons}>
                            <button className={styles.CompleteProcessing} onClick={handlePrint}>
                                دریافت رسید درخواست
                            </button>
                            <button className={styles.BackButton}
                                    onClick={() => isManager ? navigate(`/manager`) : isFinancialManager ?
                                        navigate(`/financial-manager`) : navigate(`/user`)}>
                                بازگشت
                            </button>
                        </div>
                    )}
                    {
                        (inquiryDetail.inquiry.status === 4 || showPdf) &&
                        <div style={{display: 'none'}}>
                            <ApprovedReceiptDocument ref={componentRef} device="desktop"/>
                        </div>
                    }
                    {showInquiryApprovalSuccess &&
                        <SuccessDialog
                            message="درخواست شما با موفقیت خاتمه یافت."
                            details="می‌توانید نتیجه درخواست خود را هم‌اکنون با استفاده از دکمه زیر مشاهده کنید یا در آینده از طریق صفحه درخواست‌ها و انتخاب وضعیت خاتمه یافته به آن دسترسی پیدا کنید."
                            onConfirm={() => {
                                handlePrint();
                            }}
                            buttonText="دریافت رسید درخواست"
                        />
                    }
                </div>
                {
                    showApproveConfirmation && (
                        <ConfirmationDialog
                            message={"آیا از تایید نهایی این درخواست اطمینان دارید؟ "}
                            onConfirm={handleFinalizeInquiry}
                            onCancel={() => setShowApproveConfirmation(false)}
                        />
                    )
                }
                {
                    selectedReceiptPhoto && (
                        <div className={styles.enlargedImageContainer}
                             onClick={() => setSelectedReceiptPhoto('')}>
                            <img src={selectedReceiptPhoto} alt="Enlarged Image"
                                 className={styles.enlargedImage}/>
                        </div>
                    )
                }
                {
                    selectedPdf &&
                    <PDFPreview selectedPdf={selectedPdf} setSelectedPdf={setSelectedPdf}/>
                }
                {(isDownloading || filePreviewLoading) && (
                    <div className={styles.downloadOverlay}>
                        <CircularProgress size={100}/>
                    </div>
                )}
            </div>
        );

    }
}

export default InquiryDesktop;
