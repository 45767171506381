import PropTypes from "prop-types";
import CustomTableRow from "./CustomTableRow";
import {uniqueKey} from "./util";
import "./CustomTable.css";

const CustomTable = ({
                         headerData = [],
                         data = [],
                         styles,
                         expandedRows = [],
                         expansionData = [],
                         sortConfig = {},
                         onSort
                     }) => {

    const tableHead =
        headerData.length > 0 &&
        headerData.map((row, index) => {
            return (
                <CustomTableRow
                    key={uniqueKey(row[index].value)}
                    bold
                    rowData={row}
                    borderBottom={index !== headerData.length - 1}
                    rowIndex={1}
                    styles={styles}
                    onHeaderClick={(columnIndex) => onSort(columnIndex)}
                    isHeader={true}
                    sortConfig={sortConfig}
                />
            );
        });

    const tableBody = data.map((row, index) => {
        return (
            <>
                <CustomTableRow
                    key={uniqueKey(row.value)}
                    rowData={row}
                    borderBottom={index !== data.length - 1}
                    rowIndex={index}
                    styles={styles}
                    isHeader={false}
                />
                {expandedRows.includes(index) && expansionData[index]}
            </>
        );
    });

    return <div className="container">{[tableHead, tableBody]}</div>;
};

CustomTable.propTypes = {
    headerData: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.node,
                ]),
                width: PropTypes.string,
                fade: PropTypes.bool,
            })
        )
    ),
    data: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.node,
                ]),
                width: PropTypes.string,
                fade: PropTypes.bool,
            })
        )
    ),
    styles: PropTypes.oneOf(["desktop", "mobile"]).isRequired,
    expandedRows: PropTypes.arrayOf(PropTypes.number),
    expansionData: PropTypes.arrayOf(PropTypes.node),
};

export default CustomTable;
