import React, {useState} from 'react';
import './ConfirmationDialog.css';

const ConfirmationDialog = ({message, onConfirm, onCancel}) => {
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleConfirm = () => {
        setIsConfirmed(true);
        onConfirm();
    };

    return (
        <div className="confirmation-dialog">
            <div className="confirmation-content">
                <p className="confirmation-message">{message}</p>
                <div className="confirmation-buttons">
                    <button
                        className="confirmation-button confirm"
                        onClick={handleConfirm}
                        disabled={isConfirmed}
                    >
                        {!isConfirmed ? 'بله' : 'لطفا صبر کنید ...'}
                    </button>
                    <button
                        className="confirmation-button cancel"
                        onClick={onCancel}
                    >
                        خیر
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationDialog;
