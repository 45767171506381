import {useState, useEffect} from 'react';
import {
    getInquiriesWithStatus,
    getInquiryCounts,
    getUnopenedInquiryEventsCountBatch
} from "../APIs/inquiry";

const useAuditorInquiries = (userRole, inquiriesPerPage) => {
    const [loading, setLoading] = useState(false);
    const [inquiryStatus, setInquiryStatus] = useState(userRole === 'auditor' ? 0 : 4);
    const [inquiries, setInquiries] = useState([]);
    const [totalInquiries, setTotalInquiries] = useState(0);
    const [inquiryCounts, setInquiryCounts] = useState({});
    const [updateCounts, setUpdateCounts] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({key: null, direction: null});
    const [searchCategory, setSearchCategory] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);

    const fetchInquiries = async () => {
        setLoading(true);
        try {
            const params = {
                status: inquiryStatus,
                page: currentPage,
                perPage: inquiriesPerPage,
                subject: null,
                fullname: null,
                org: null,
                product: null,
                sortColumn: sortConfig.key,
                sortDirection: sortConfig.direction
            };

            if (searchQuery.trim() !== "") {
                switch (searchCategory) {
                    case "subject":
                        params.subject = searchQuery;
                        break;
                    case "fullname":
                        params.fullname = searchQuery;
                        break;
                    case "org":
                        params.org = searchQuery;
                        break;
                    case "product":
                        params.product = searchQuery;
                        break;
                    default:
                        params.subject = searchQuery;
                        params.fullname = searchQuery;
                        params.org = searchQuery;
                        params.product = searchQuery;
                        break;
                }
            } else {
                setIsSearchPerformed(false);
            }

            const inquiriesData = await getInquiriesWithStatus(
                params.status,
                params.page,
                params.perPage,
                params.subject,
                params.fullname,
                params.org,
                params.product,
                params.sortColumn === 3 ? "date" :
                    params.sortColumn === 4 ? "fullname" :
                        params.sortColumn === 5 ? "org" : null,
                params.sortDirection
            );

            setInquiries(inquiriesData.data.inquiries);
            setTotalInquiries(inquiriesData.data.total_inquiries_count);

            if (userRole === 'auditor') {
                const {data: {inquiry_counts_by_status}} = await getInquiryCounts();
                setInquiryCounts(inquiry_counts_by_status);

                const inquiryIds = inquiriesData.data.inquiries.map(inquiry => inquiry.id);
                const {data: counts} = await getUnopenedInquiryEventsCountBatch(inquiryIds);
                const countsData = {};
                counts.forEach(({inquiry_id, count}) => {
                    countsData[inquiry_id] = count;
                });
                setUpdateCounts(countsData);
            }
        } catch (error) {
            console.error("Error fetching inquiries:", error);
            setInquiries([]);
            setUpdateCounts({});
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInquiries();
    }, [inquiryStatus, currentPage, inquiriesPerPage, sortConfig]);

    return {
        loading,
        inquiryStatus,
        setInquiryStatus,
        inquiries,
        totalInquiries,
        inquiryCounts,
        updateCounts,
        currentPage,
        setCurrentPage,
        sortConfig,
        setSortConfig,
        searchCategory,
        setSearchCategory,
        searchQuery,
        setSearchQuery,
        isSearchPerformed,
        setIsSearchPerformed,
        fetchInquiries
    };
};

export default useAuditorInquiries;