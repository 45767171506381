import {FaTimes} from "@react-icons/all-files/fa/FaTimes";
import React from "react";
import styles from "./PDFPreview.module.css"

const PDFPreview = ({ selectedPdf, setSelectedPdf }) => {
    // const handlePdfDownload = (pdfUrl) => {
    //     const link = document.createElement('a');
    //     link.href = pdfUrl;
    //     link.download = 'پیش‌فاکتور.pdf';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    return (
        <div className={styles.pdfPreviewModal} onClick={() => setSelectedPdf(null)}>
            <div className={styles.pdfPreviewContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.pdfPreviewHeader}>
                    <button className={styles.closePdfPreview} onClick={() => setSelectedPdf(null)}>
                        <FaTimes />
                    </button>
                    <h2 className={styles.pdfPreviewTitle}>پیش‌نمایش پیش‌فاکتور</h2>
                </div>
                <iframe src={selectedPdf} width="100%" height="500px" title="پیش‌نمایش PDF" />
                {/* <div className={styles.pdfPreviewFooter}>
                    <button className={styles.downloadPdf} onClick={() => handlePdfDownload(selectedPdf)}>
                        <FaDownload/> دانلود پیش‌فاکتور
                    </button>
                </div> */}
            </div>
        </div>
    );
}

export default PDFPreview;