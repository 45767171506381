import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const TimelineItemComponent = ({date, time, content, author, isGreen, isAuditor, isLast, isMobile}) => {
    const dateAndTimeFontSize = isMobile ? '3vw' : '0.9vw';
    const contentFontSize = isMobile ? '3.5vw' : '0.9vw';
    const authorFontSize = isMobile ? '3vw' : '0.8vw';
    const authorMarginBottom = isMobile ? '8vw' : '2vw';

    return (
        <TimelineItem>
            <TimelineOppositeContent
                align="right"
                color="text.secondary"
                sx={{
                    flex: 0.05,
                    marginTop: "-15px",
                    paddingRight: "0px",
                }}
            >
                <Typography align="left"
                            sx={{fontFamily: 'IRANSans', fontSize: dateAndTimeFontSize}}>{date}</Typography>
                <Typography align="left"
                            sx={{fontFamily: 'IRANSans', fontSize: dateAndTimeFontSize}}>{time}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot
                    color={"primary"}
                    sx={{
                        my: 0,
                        borderWidth: '3px',
                        '&.MuiTimelineDot-filled': {
                            boxShadow: 'none'
                        },
                    }}
                />
                {!isLast && <TimelineConnector sx={{
                    backgroundColor: 'var(--primary-light-color)',
                    width: '2px',
                    height: '50px'
                }}/>}
            </TimelineSeparator>
            <TimelineContent
                sx={{
                    flex: 0.95,
                    marginTop: "-15px",
                    paddingLeft: "16px",
                }}
            >
                <Typography align="right" sx={{
                    fontFamily: 'IRANSans', fontSize: contentFontSize, fontWeight: 'bold',
                    color: isGreen ? 'var(--primary-light-color)' : isAuditor ? 'var(--primary-dark-color)' : 'var(--user-comment-color)'
                }}>
                    {content}
                </Typography>
                {author &&
                    <Typography align="right" sx={{
                        fontFamily: 'IRANSans', fontSize: authorFontSize, color: 'var(--grey-color)',
                        marginBottom: authorMarginBottom
                    }}>
                        توسط {author}
                    </Typography>
                }
            </TimelineContent>
        </TimelineItem>
    );
};

const TimeLine = ({items, isMobile = false}) => {
    return (
        <Timeline position="right">
            {items.map((item, index) => (
                <TimelineItemComponent
                    key={index}
                    {...item}
                    isLast={index === items.length - 1}
                    isMobile={isMobile}
                />
            ))}
        </Timeline>
    );
};

export default TimeLine;
