import React, {useState} from 'react';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import styles from "./ZoomableRotatableImage.module.css";

const ZoomableRotatableImage = ({image}) => {
    const [rotation, setRotation] = useState(0);

    const rotate = () => {
        setRotation((prev) => (prev + 90) % 360);
    };

    return (
        <div className={styles.container}>
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                minScale={0.5}
                maxScale={3}
                centerOnInit={true}
                limitToBounds={false} // Allow dragging beyond bounds
            >
                {({zoomIn, zoomOut, resetTransform}) => (
                    <>
                        <div className={styles.controls}>
                            <button onClick={() => zoomIn()}>بزرگنمایی</button>
                            <button onClick={() => zoomOut()}>کوچک نمایی</button>
                            <button onClick={() => resetTransform()}>بازنشانی</button>
                            <button onClick={rotate}>چرخش 90 درجه</button>
                        </div>
                        <div className={styles.imageContainer}>
                            <TransformComponent>
                                <img
                                    src={image.data_uri}
                                    alt="Receipt"
                                    className={styles.image}
                                    style={{transform: `rotate(${rotation}deg)`}}
                                />
                            </TransformComponent>
                        </div>
                    </>
                )}
            </TransformWrapper>
        </div>
    );
};

export default ZoomableRotatableImage;