import React from 'react';
import styles from './Pagination.module.css';

const Pagination = ({currentPage, totalPages, onPageChange}) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
        let startPage, endPage;

        if (totalPages <= maxPagesToShow) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage <= halfMaxPagesToShow) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + halfMaxPagesToShow >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - halfMaxPagesToShow;
            endPage = currentPage + halfMaxPagesToShow;
        }

        if (startPage > 1) {
            pageNumbers.push(
                <span
                    key={1}
                    className={`${styles.PageNumber} ${currentPage === 1 ? styles.Active : ''}`}
                    onClick={() => onPageChange(1)}
                >
                    1
                </span>
            );
            if (startPage > 2) {
                pageNumbers.push(<span key="ellipsis-start">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <span
                    key={i}
                    className={`${styles.PageNumber} ${currentPage === i ? styles.Active : ''}`}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </span>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pageNumbers.push(<span key="ellipsis-end">...</span>);
            }
            pageNumbers.push(
                <span
                    key={totalPages}
                    className={`${styles.PageNumber} ${currentPage === totalPages ? styles.Active : ''}`}
                    onClick={() => onPageChange(totalPages)}
                >
                    {totalPages}
                </span>
            );
        }

        return pageNumbers;
    };

    return (
        <div className={styles.PaginationContainer}>
            <div className={styles.PaginationControls}>
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`${styles.PaginationButton} ${currentPage === 1 ? styles.Disabled : ''}`}
                >
                    قبلی
                </button>
                {renderPageNumbers()}
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`${styles.PaginationButton} ${currentPage === totalPages ? styles.Disabled : ''}`}
                >
                    بعدی
                </button>
            </div>
        </div>
    );
};

export default Pagination;