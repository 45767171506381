import {useMobile} from "../../MobileContext";
import {useState} from "react";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import styles from "../../styles/PageLayout.module.css";
import React from 'react';
import DashboardDesktop from "../dashboard/DashboardDesktop";

const ManagerDashboard = () => {
    const isMobile = useMobile();

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <> </>
            ) : (
                <DesktopHeader
                    role={"manager"}
                    showNotification={false}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={styles.rightSectionContainer}>
                    {isMobile ? (
                        <> </>
                    ) : (
                        <DashboardDesktop role={"manager"}/>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ManagerDashboard;