export const AUDITOR_DASHBOARD_OPTIONS = {
    OVERVIEW: 1,
    FINANCIAL: 2,
    SELLERS: 3,
    PERFORMANCE_REPORT: 4,
};

export const AUDITOR_DASHBOARD_OPTION_LABELS = {
    [AUDITOR_DASHBOARD_OPTIONS.OVERVIEW]: 'نمای کلی',
    [AUDITOR_DASHBOARD_OPTIONS.FINANCIAL]: 'گزارش مالی',
    [AUDITOR_DASHBOARD_OPTIONS.SELLERS]: 'فروشندگان',
    [AUDITOR_DASHBOARD_OPTIONS.PERFORMANCE_REPORT]: 'گزارش عملکرد',
};