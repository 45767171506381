import {FaChevronRight} from "@react-icons/all-files/fa/FaChevronRight";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CustomTable from "../../../components/CustomTable/CustomTable";
import stylesMobile from "../../../styles/user/mobile/UserInquiry.module.css";
import LoadingComponent from "../../../components/Loading/Loading";
import convertDateToShamsi from "../../../utils/functions"
import {FaCheck} from "@react-icons/all-files/fa/FaCheck";
import {FaStar} from "@react-icons/all-files/fa/FaStar";
import ApprovedReceiptDocument from "../../common/ApprovedReceiptDocument";
import {useReactToPrint} from "react-to-print";
import InquiryHistory from "../../common/InquiryHistory";
import SimpleTable from "../../../components/SimpleTable/SimpleTable";
import {finalizeInquiry, getPhoto} from "../../../APIs/inquiry";
import {FaRegComment} from "@react-icons/all-files/fa/FaRegComment";
import {IoIosArrowDown} from "@react-icons/all-files/io/IoIosArrowDown";
import {IoIosArrowUp} from "@react-icons/all-files/io/IoIosArrowUp";
import {IoMdDownload} from "@react-icons/all-files/io/IoMdDownload";
import ConfirmationDialog from "../../../components/Confirmation/ConfirmationDialog";
import emptyTable from "../../../assets/images/empty_table.svg"
import InquiryStatusIcon from "../../../components/InquiryStatusIcon";
import SuccessDialog from "../../../components/Success/SuccessDialog";
import useFetchInquiry from "../../../hooks/useFetchInquiry";
import ReactLoading from "react-loading";
import {CircularProgress} from "@mui/material";

const UserInquiryMobile = () => {
    const styles = stylesMobile;
    const navigate = useNavigate();
    const {id} = useParams();
    const [filePreviewLoading, setFilePreviewLoading] = useState(false);

    // State variables fetched in useEffect
    const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
    const [showInquiryApprovalSuccess, setShowInquiryApprovalSuccess] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const [expandedRows, setExpandedRows] = useState([]);
    const [selectedReceiptPhoto, setSelectedReceiptPhoto] = useState(null);

    const [showInquiryHistoryModal, setShowInquiryHistoryModal] = useState(false);

    // Convert to PDF
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setShowInquiryApprovalSuccess(false);
            setShowApproveConfirmation(false);
            if (showPdf) {
                window.location.reload();
            }
        },
        onPrintError: () => {
            setShowInquiryApprovalSuccess(false);
            setShowApproveConfirmation(false);
        }
    });

    // Fetch Inquiry
    const {
        inquiryLoading,
        receiptsLoading,
        inquiryDetail,
        receiptsDetail,
        fetchAll
    } = useFetchInquiry(id);

    useEffect(() => {
        fetchAll();
    }, []);
    //

    const toggleRow = (index) => {
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter((row) => row !== index)); // Collapse if already expanded
        } else {
            setExpandedRows([...expandedRows, index]); // Expand if not expanded
        }
    };

    const headerData = [
        [
            {value: "فروشنده"},
            {value: ""},
            {value: ""}
        ]
    ];

    function findPhotoById(id) {
        for (let i = 0; i < inquiryDetail.inquiry.photos.length; i++) {
            if (inquiryDetail.inquiry.photos[i].id === id) {
                return inquiryDetail.inquiry.photos[i];
            }
        }
        return null;
    }

    const dataURItoBlob = (dataUri, fileType) => {
        const byteString = atob(dataUri.split(',')[1]);
        const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], {type: mimeString || fileType});
    };

    const handleDownload = async (photo_id) => {
        setFilePreviewLoading(true);

        try {
            const file = await getPhoto(photo_id, id);
            if (file?.data.photo) {
                const {file_format, data_uri} = file.data.photo;
                const blob = dataURItoBlob(data_uri, file_format);
                const url = URL.createObjectURL(blob);


                const link = document.createElement('a');
                link.href = url;
                link.download = `download.${file_format === 'pdf' ? 'pdf' : 'png'}`; // Default to png for images

                // Trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error("Error fetching photo:", error);
        } finally {
            setFilePreviewLoading(false);
        }
    };

    function gettableData() {
        if (receiptsDetail.length !== 0) {
            return receiptsDetail.map((receipt, index) => {
                const rowData = [
                    {value: receipt.store.name},
                    {
                        value: findPhotoById(receipt.photo) &&
                        (findPhotoById(receipt.photo).is_selected_by_user || findPhotoById(receipt.photo).is_selected_by_auditor) ? (
                            <div className={styles.ChoicesContainer}>
                                {findPhotoById(receipt.photo).is_selected_by_user && (
                                    <span className={styles.myChoiceText}>
                                        <FaStar/>&nbsp;&nbsp;&nbsp;انتخاب من
                                    </span>
                                )}
                                {findPhotoById(receipt.photo).is_selected_by_auditor && (
                                    <span className={styles.auditorChoiceText}>
                                        <FaCheck/>&nbsp;&nbsp;&nbsp;انتخاب کارشناس
                                    </span>
                                )}
                            </div>
                        ) : "",
                        paddingTop: "0",
                        paddingBottom: "0"
                    },
                    {
                        value:
                            <div className={styles.Icons}>
                                {/*<MdRemoveRedEye*/}
                                {/*    className={styles.EyeIcon}*/}
                                {/*    onClick={() => handleDownloadInNewTab(inquiryDetail.inquiry.photos[index].id)}*/}
                                {/*/>*/}
                                <IoMdDownload
                                    className={styles.DownloadIcon}
                                    onClick={() => handleDownload(inquiryDetail.inquiry.photos[index].id)}
                                />
                                {expandedRows.includes(index) ?
                                    <IoIosArrowUp
                                        onClick={() => toggleRow(index)}
                                        className={styles.ExpandIcon}
                                    />
                                    :
                                    <IoIosArrowDown
                                        onClick={() => toggleRow(index)}
                                        className={styles.ExpandIcon}
                                    />
                                }
                            </div>
                    },
                ];

                return rowData;
            });
        } else {
            return [];
        }
    }

    const detailRowHeader = [
        {value: "نام کالا"},
        {value: "تعداد"},
        // { value: "واحد" },
        {value: "قیمت واحد"},
        {value: "قیمت کل"},
    ]

    function getDetailedRowData(index) {
        const receipt = receiptsDetail[index];
        return receipt.items.map(item => [
            {value: item.product.name},
            {value: item.quantity ? item.quantity.toLocaleString() : ""},
            // { value: item.unit ? item.unit : "-" },
            {value: item.unit_price ? item.unit_price.toLocaleString() : ""},
            {value: item.unit_price ? (item.unit_price * item.quantity).toLocaleString() : ""},
        ]);
    }

    function getSecondLastRow(index) {
        const receipt = receiptsDetail[index];
        return {
            right: `سایر هزینه‌ها: `,
            left: `${receipt.additional_costs.toLocaleString()} ریال`
        };
    }

    function getLastRow(index) {
        const receipt = receiptsDetail[index];
        return {
            right: `جمع کل: `,
            left: `${receipt.total_price.toLocaleString()} ریال`
        };
    }

    const handleFinalizeInquiry = async () => {
        try {
            // setLoading(true);
            await finalizeInquiry(id);
            setShowPdf(true);
        } catch (error) {
            // 500 Error
        } finally {
            // setLoading(false);
            setShowApproveConfirmation(false);
            setShowInquiryApprovalSuccess(true);
        }
    }

    const handleOpenInquiryHistoryModal = () => {
        setShowInquiryHistoryModal(true);
    };

    const handleCloseInquiryHistoryModal = () => {
        setShowInquiryHistoryModal(false);
    };

    function getExpansionData(data) {
        return (
            data.map((row, index) => (
                expandedRows.includes(index) ? (
                    <div
                        key={index}
                        className={styles.expansionRowContainer}
                        style={{
                            borderTop: index % 2 === 0 ? 'none' : '1px solid lightgrey',
                            backgroundColor: index % 2 === 1 ? 'rgba(246, 247, 248, 1)' : 'white',
                            direction: "rtl"
                        }}
                    >
                        <SimpleTable
                            headers={detailRowHeader}
                            data={getDetailedRowData(index)}
                            columnWidths={[33, 33, 34]}
                            secondLastRowData={getSecondLastRow(index)}
                            lastRowData={getLastRow(index)}
                            device={"mobile"}
                        />
                    </div>
                ) : null
            ))
        );
    }

    if (inquiryDetail === null || inquiryLoading) {
        return (
            <LoadingComponent isMobile={true}/>
        );
    } else if (inquiryDetail === "Error") {
        // TODO
    } else {
        const rowData = gettableData();
        return (
            <div className={styles.inquiryContainer}>
                <div className={styles.headingContent}>
                    <FaRegComment className={styles.CommentIcon} onClick={() => handleOpenInquiryHistoryModal()}/>

                    <div className={styles.NameContainer}>
                        <span className={styles.inquiryHeading}>
                            {inquiryDetail.inquiry.name}
                        </span>
                        <FaChevronRight onClick={() => navigate("/user")} className={styles.goBackIcon}/>
                    </div>
                </div>

                <div className={styles.InfoContainer}>
                    <div className={styles.Field}>
                        <span className={styles.FieldText}>عنوان درخواست: </span>
                        <span className={styles.categoryAndDate}>
                            {inquiryDetail.inquiry.subject}
                        </span>
                    </div>
                    <div className={styles.Field}>
                        <span className={styles.FieldText}>وضعیت درخواست: </span>
                        <InquiryStatusIcon
                            inquiryStatus={inquiryDetail.inquiry.is_returned ? 6 : inquiryDetail.inquiry.status}
                            isMobile={true}/>
                    </div>
                    <div className={styles.Field}>
                        <span className={styles.FieldText}>تاریخ ثبت در سامانه: </span>
                        <span className={styles.categoryAndDate}>
                            {convertDateToShamsi(inquiryDetail.inquiry.submitted_date)}
                        </span>
                    </div>
                </div>

                {receiptsLoading ?
                    <div className={styles.loading_container}>
                        <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                      width='50px'
                                      height='50px'/>
                    </div>
                    :
                    rowData.length === 0 ?
                        <div className={styles.EmptyTableContainer}>
                            <img className={styles.EmptyTableImage} src={emptyTable} alt="Empty Table"/>
                            <span className={styles.ExplanationText}>هنوز هیچ پیش‌فاکتوری پردازش نشده است.</span>
                        </div>
                        :
                        <div
                            className={[3, 4].includes(inquiryDetail.inquiry.status) ? styles.table_box : styles.table_box_extended}>
                            <CustomTable
                                headerData={headerData}
                                data={rowData}
                                styles={"mobile"}
                                expandedRows={expandedRows}
                                expansionData={getExpansionData(gettableData())}
                            />
                        </div>
                }
                {selectedReceiptPhoto && (
                    <div className={styles.enlargedImageContainer} onClick={() => setSelectedReceiptPhoto('')}>
                        <img src={selectedReceiptPhoto} alt="Enlarged Image" className={styles.enlargedImage}/>
                    </div>
                )}

                {inquiryDetail.inquiry.status !== 2 &&
                    <div className={styles.finalizeOrEditInquiryButtons}>
                        {inquiryDetail.inquiry.status === 3 && (
                            <button
                                className={styles.finalizeButton}
                                onClick={() => setShowApproveConfirmation(true)}
                            >
                                تایید نهایی
                            </button>
                        )}
                        {![2, 4, 5].includes(inquiryDetail.inquiry.status) &&
                            <button
                                className={inquiryDetail.inquiry.status === 1 ? styles.editButtonWhole : styles.editButton}
                                onClick={() => navigate(`/user/edit-inquiry/${id}`)}
                            >
                                ویرایش درخواست
                            </button>
                        }
                        {
                            inquiryDetail.inquiry.status === 4 &&
                            <button className={styles.editButtonWhole} onClick={handlePrint}>
                                دریافت رسید درخواست
                            </button>
                        }
                        {(inquiryDetail.inquiry.status === 4 || showPdf) &&
                            <div style={{display: 'none'}}>
                                <ApprovedReceiptDocument ref={componentRef} device="mobile"/>
                            </div>
                        }


                    </div>
                }
                {showApproveConfirmation && (
                    <ConfirmationDialog
                        message={"آیا از تایید نهایی این درخواست اطمینان دارید؟ "}
                        onConfirm={handleFinalizeInquiry}
                        onCancel={() => setShowApproveConfirmation(false)}
                    />
                )}

                {showInquiryApprovalSuccess &&
                    <SuccessDialog
                        message="درخواست شما با موفقیت خاتمه یافت."
                        details="می‌توانید نتیجه درخواست خود را هم‌اکنون با استفاده از دکمه زیر مشاهده کنید یا در آینده از طریق صفحه درخواست‌ها و انتخاب وضعیت خاتمه یافته به آن دسترسی پیدا کنید."
                        onConfirm={() => {
                            handlePrint();
                        }}
                        buttonText="دریافت رسید درخواست"
                    />
                }

                {
                    showInquiryHistoryModal && (
                        <InquiryHistory onClose={handleCloseInquiryHistoryModal} isMobile={true}/>
                    )
                }
                {(filePreviewLoading) && (
                    <div className={styles.downloadOverlay}>
                        <CircularProgress size={100}/>
                    </div>
                )}

            </div>
        );
    }
};

export default UserInquiryMobile;
