import styles from "../../../styles/auditor/desktop/ReceiptProcessingModal.module.css"
import {FaFilePdf} from "@react-icons/all-files/fa/FaFilePdf";
import {FaRegTrashAlt} from "@react-icons/all-files/fa/FaRegTrashAlt";
import {FaStar} from "@react-icons/all-files/fa/FaStar";
import {IoIosArrowDown} from "@react-icons/all-files/io/IoIosArrowDown";
import {IoIosArrowUp} from "@react-icons/all-files/io/IoIosArrowUp";
import {MdClose} from "@react-icons/all-files/md/MdClose";
import {MdModeEdit} from "@react-icons/all-files/md/MdModeEdit";
import React, {useEffect, useMemo, useRef, useState} from "react";
import UploadReceipt from "../../../components/UploadReceipt/UploadReceipt";
import {ToastContainer} from "react-toastify";
import toast_style from "../../../styles/Toastify.css";
import CreatableSelect from "react-select/creatable";
import InlineError from "../../../components/InlineError/InlineError";
import TimeLine from "../../../components/TimeLine/TimeLine";
import {useParams} from "react-router-dom";
import {createStore, getAllStores, getStoreById} from "../../../APIs/store";
import {getProductById} from "../../../APIs/product"
import PDFPreview from "../../../components/PDFPreview/PDFPreview";
import LoadingComponent from "../../../components/Loading/Loading";
import CustomTable from "../../../components/CustomTable/CustomTable";
import SelectProduct from "./SelectProduct";
import ProductSearchComponent from "./SearchProduct";
import {addSuggestionReceipt, createReceipt, editReceipt, removeReceiptItems} from "../../../APIs/receipt";
import {creatableSelectCustomStylesStore} from "../../common/utils";
import emptyTable from "../../../assets/images/empty_table.svg";
import {addReceiptItem} from "../../../APIs/product";
import ConfirmationDialog from "../../../components/Confirmation/ConfirmationDialog";
import {getPhoto} from "../../../APIs/inquiry";
import useComments from "../../../hooks/useComments";
import ReactLoading from "react-loading";
import ZoomableRotatableImage from "../../../components/ReceiptProcessingImageSection/ZoomableRotatableImage";
import axios from "axios";

const ReceiptProcessingModal = ({
                                    onClose,
                                    receiptName,
                                    receiptFileId,
                                    selectedReceiptForEdit,
                                    isSuggestion,
                                    selectedProducts
                                }) => {
    const [page, setPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const {id} = useParams();
    const [receiptFile, setReceiptFile] = useState(null);
    const [fileLoading, setFileLoading] = useState(false);
    const [storesLoading, setStoresLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 50); // Adjust the delay as needed

        return () => clearTimeout(timer);
    }, []);

    // page 1
    const [selectedStore, setSelectedStore] = useState(null);
    const [stores, setStores] = useState([]);
    const [storeObj, setStoreObj] = useState({});
    const [storeNewPhone, setStoreNewPhone] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("نقدی");
    const [showSuggestFileError, setShowSuggestFileError] = useState(false);

    // page 2
    const [additionalCosts, setAdditionalCosts] = useState(0);
    const [receiptProducts, setReceiptProducts] = useState([]);
    const [receiptEditIndex, setReceiptEditIndex] = useState(null);
    const [checkInlineValuesError, setCheckInlineValuesError] = useState(false);

    // Page 3
    const [option, setOption] = useState(1);
    const [selectedProduct, setSelectedProduct] = useState({});
    const selectProductRef = useRef();
    const [quantity, setQuantity] = useState(0);
    const [unit, setUnit] = useState('');
    const [unitPrice, setUnitPrice] = useState(0);
    const [showUnitPriceError, setShowUnitPriceError] = useState(false);
    const [showQuantityError, setShowQuantityError] = useState(false);
    const [showReceiptProcessingConfirmation, setShowReceiptProcessingConfirmation] = useState(false);

    const [expandedRows, setExpandedRows] = useState([]);
    const handleExpandRow = (index) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(index)
                ? prevExpandedRows.filter((row) => row !== index)
                : [...prevExpandedRows, index]
        );
    };

    const handleApproveProduct = (quantity, unit, unitPrice) => {
        return selectProductRef.current.approveProductSelection(quantity, unit, unitPrice);
    };
    const handleValidateProduct = () => {
        return selectProductRef.current.productValidataion();
    }
    const addProductToReceipt = (product) => {
        setReceiptProducts(prevProducts => {
            if (receiptEditIndex !== null) {
                // Replace the product at the specified index
                const updatedProducts = [...prevProducts];
                updatedProducts[receiptEditIndex] = product;
                return updatedProducts;
            } else {
                // Add new product
                return [...prevProducts, product];
            }
        });
    };

    const abortControllerRef = useRef(null);
    const fetchFile = async () => {
        try {
            setFileLoading(true);
            abortControllerRef.current = new AbortController();
            const file = await getPhoto(receiptFileId, id, abortControllerRef.current.signal);
            setReceiptFile(file.data.photo);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error('Error fetching file:', error);
            }
        } finally {
            setFileLoading(false);
        }
    };

    useEffect(() => {
        fetchFile();

        // return () => {
        //     if (abortControllerRef.current) {
        //         abortControllerRef.current.abort();
        //     }
        // };
    }, []);

    useEffect(() => {
        const fetchAllStores = async () => {
            setStoresLoading(true);
            try {
                const storesData = await getAllStores();
                setStores(storesData.data.stores);
            } catch (error) {
                console.error('Error fetching stores:', error);
                setStores([]);
            } finally {
                setStoresLoading(false);
            }
        };

        const setSelectedReceiptData = async () => {
            if (selectedReceiptForEdit !== null) {
                setSelectedStore({
                    "value": selectedReceiptForEdit.store.id,
                    "label": selectedReceiptForEdit.store.name
                });

                const storeId = parseInt(selectedReceiptForEdit.store.id);
                try {
                    const store = await getStoreById(storeId);
                    setStoreObj(store.data);
                } catch (error) {
                    console.error('Error fetching store:', error);
                    setStoreObj({});
                }

                try {
                    let products = [];
                    for (let i = 0; i < selectedReceiptForEdit.items.length; i++) {
                        const {data: {product}} = await getProductById(selectedReceiptForEdit.items[i].product.id);
                        let product_data = {
                            id: selectedReceiptForEdit.items[i].product.id,
                            name: selectedReceiptForEdit.items[i].product.name,
                            quantity: selectedReceiptForEdit.items[i].quantity,
                            unitPrice: selectedReceiptForEdit.items[i].unit_price,
                            unit: selectedReceiptForEdit.items[i].unit,
                            main_category: product.main_category,
                            sub_category: product.sub_category,
                            brand: product.brand,
                            attributes: product.attributes,
                        }
                        products.push(product_data);
                    }
                    setReceiptProducts(products);

                    setAdditionalCosts(selectedReceiptForEdit.additional_costs);
                    setPaymentMethod(selectedReceiptForEdit.payment_method);
                } catch (error) {
                    console.error('Error getting product:', error);
                }

            } else if (selectedProducts.length) {
                let products = [];
                const uniqueProducts = selectedProducts.filter((product, index, self) =>
                    index === self.findIndex((p) => p.id === product.id)
                );
                for (let i = 0; i < uniqueProducts.length; i++) {
                    let product_data = {
                        id: uniqueProducts[i].id,
                        name: uniqueProducts[i].name,
                        quantity: 0,
                        unitPrice: 0,
                        unit: null,
                        main_category: uniqueProducts[i].main_category,
                        sub_category: uniqueProducts[i].sub_category,
                        brand: uniqueProducts[i].brand,
                        attributes: uniqueProducts[i].attributes,
                    }
                    products.push(product_data);
                }
                setReceiptProducts(products);
            }
        };

        fetchAllStores();
        setSelectedReceiptData();
    }, []);

    // Comment
    const {
        timelineItems,
        isFetchingComments
    } = useComments(id);
    //

    const [suggestedReceiptFile, setSuggestedReceiptFile] = useState({});

    // Errors
    const [showStoreError, setShowStoreError] = useState(false);
    const [showPhoneError, setShowPhoneError] = useState(false);

    // Moving between fields with Enter
    const inputRefs = useRef([]);
    const assignRef = (index) => (el) => {
        inputRefs.current[index] = el;
    };
    const handleKeyDown = (e, currentIndex) => {
        if (e.key === 'Enter') {
            let nextIndex = currentIndex + 1;
            while (nextIndex < inputRefs.current.length) {
                const nextInput = inputRefs.current[nextIndex];
                if (nextInput && !nextInput.disabled) {
                    nextInput.focus();
                    break;
                }
                nextIndex++;
            }
        }
    };

    // Download PDF
    const [selectedPdf, setSelectedPdf] = useState(null);

    const handlePdfClick = (pdfUrl) => {
        setSelectedPdf(pdfUrl);
    };

    function PDFPreviewFunction({file}) {
        return (
            <div className={styles.uploadedPdf} onClick={() => handlePdfClick(file.data_uri)}
                 style={{cursor: 'pointer'}}>
                <FaFilePdf className={styles.pdfIcon}/>
                <span className={styles.pdfText}>جهت دانلود و مشاهده فایل PDF، کلیک کنید.</span>
            </div>
        );
    }

    const memoizedReceiptImageSection = useMemo(() => (
        <ReceiptImageSection/>
    ), [receiptFile, suggestedReceiptFile]);

    function ExistingReceiptImage() {
        if (receiptFile !== null) {
            return receiptFile.file_format === "pdf" ? <PDFPreviewFunction file={receiptFile}/> :
                <ZoomableRotatableImage image={receiptFile}/>;
        }
        return suggestedReceiptFile.file_format === "pdf" ? <PDFPreviewFunction file={suggestedReceiptFile}/> :
            <ZoomableRotatableImage image={suggestedReceiptFile}/>;
    }

    function ReceiptImageSection() {
        if (receiptFile !== null || Object.values(suggestedReceiptFile).length !== 0) {
            return (
                <div className={styles.Receipt}>
                    <ExistingReceiptImage/>
                </div>
            );
        }
        return (
            <div className={styles.Receipt}>
                <UploadReceipt onImageSelected={setSuggestedReceiptFile} showSuggestFileError={showSuggestFileError}/>
            </div>
        );
    }

    const handleStoreChange = (selectedOption) => {
        if (selectedOption && selectedOption.value !== 'create') {
            setSelectedStore(selectedOption);
            const storeId = parseInt(selectedOption.value);
            const store = stores.find(store => store.id === storeId);
            setStoreObj(store);
            setShowStoreError(false);
        }
        if (selectedOption === null) {
            setSelectedStore(null);
            setStoreObj({});
        }
    };

    const handleStoreCreate = (inputValue) => {
        const newValue = {value: inputValue, label: inputValue};
        setSelectedStore(newValue);
        setStoreObj({});
        setShowStoreError(false);
    };

    const handlePhoneChange = (event) => {
        const newPhone = event.target.value;
        setStoreNewPhone(newPhone);
    }

    function StoreNameField() {
        return (
            <div className={styles.FieldContainer}>
                <span className={styles.Subheading}>
                    نام فروشنده:
                    <span style={{color: "red"}}> *</span>
                </span>
                <CreatableSelect
                    isClearable
                    value={selectedStore}
                    options={stores.map((store) => ({
                        value: store.id,
                        label: store.name
                    }))}
                    onChange={handleStoreChange}
                    className={`${styles.ChooseProductModalDropdown} ${showStoreError ? `${styles.errorField} ${styles.shake}` : ''}`}
                    placeholder={"انتخاب فروشنده"}
                    formatCreateLabel={(inputValue) => ` افزودن "${inputValue}"`}
                    onCreateOption={handleStoreCreate}
                    noOptionsMessage={() => "گزینه‌ای وجود ندارد"}
                    ref={assignRef(0)}
                    onKeyDown={e => handleKeyDown(e, 0)}
                    styles={creatableSelectCustomStylesStore}
                />
            </div>
        );
    }

    function PaymentMethodField() {
        return (
            <div className={styles.FieldContainer}>
                <span className={styles.Subheading}>
                    نحوه پرداخت:
                    <span style={{color: "red"}}> *</span>
                </span>
                <select
                    className={styles.SelectDropdown}
                    value={paymentMethod}
                    onChange={(event) => setPaymentMethod(event.target.value)}
                    ref={assignRef(2)}
                    onKeyDown={e => handleKeyDown(e, 2)}
                >
                    <option className={styles.SelectOption}>نقدی</option>
                    <option className={styles.SelectOption}>اعتباری</option>
                    <option className={styles.SelectOption}>سایر</option>
                </select>
            </div>
        );
    }

    const arabicToEnglishDigits = (str) => {
        try {
            str = String(str); // Convert to string if it's not already
            return str.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
        } catch (error) {
            console.error("Error converting Arabic digits to English:", error);
            return str; // Return the original input if an error occurs
        }
    };

    const handleAdditionalCostsChange = (event) => {
        let value = event.target.value;
        value = value.replace(/,/g, '');
        value = arabicToEnglishDigits(value);

        const newAdditionalCosts = parseFloat(value);

        setAdditionalCosts(Number.isNaN(newAdditionalCosts) ? 0 : newAdditionalCosts);
    };

    const handleUnitPriceChange = (event) => {
        let value = event.target.value.replace(/,/g, '');
        value = arabicToEnglishDigits(value);

        const newUnitPrice = parseFloat(value);

        setUnitPrice(Number.isNaN(newUnitPrice) ? 0 : newUnitPrice);
        setShowUnitPriceError(false);
    };

    const handleInlineQuantityChange = (index, value) => {
        let new_value = arabicToEnglishDigits(value.replace(/,/g, ''));
        const newQuantity = parseFloat(new_value);

        const newReceiptProducts = [...receiptProducts];
        newReceiptProducts[index].quantity = Number.isNaN(newQuantity) ? '' : newQuantity;
        setReceiptProducts(newReceiptProducts);
    };

    const handleInlineUnitPriceChange = (index, value) => {
        let new_value = arabicToEnglishDigits(value.replace(/,/g, ''));
        const newUnitPrice = parseFloat(new_value);

        const newReceiptProducts = [...receiptProducts];
        newReceiptProducts[index].unitPrice = Number.isNaN(newUnitPrice) ? '' : newUnitPrice;
        setReceiptProducts(newReceiptProducts);
    };

    const handleUnitChange = (event) => {
        setUnit(event.target.value);
    };

    const handleQuantityChange = (event) => {
        let value = event.target.value.replace(/,/g, '');
        value = arabicToEnglishDigits(value);

        const newQuantity = parseFloat(value);

        setQuantity(Number.isNaN(newQuantity) ? 0 : newQuantity);
        setShowQuantityError(false);
    }

    const handleEditProduct = (product, index) => {
        setSelectedProduct({value: product.id, label: product.name});

        setQuantity(product.quantity);
        setUnit(product.unit);
        setUnitPrice(product.unitPrice);

        setReceiptEditIndex(index);
        setPage(3);
        setOption(2);
    }

    const handleDeleteProduct = (index) => {
        const updatedProducts = [...receiptProducts];
        updatedProducts.splice(index, 1);
        setReceiptProducts(updatedProducts);
    };

    function RenderReceiptProducts() {
        const header = [
            [
                {value: "نام کالا"},
                {value: "تعداد"},
                // {value: "واحد"},
                {value: "قیمت واحد *"},
                {value: ""}
            ]
        ];

        const rows = receiptProducts.map((product, index) => {
            return [
                {value: product.name, color: "black", bold: true},
                {
                    value: product.quantity === 0 ? '' : product.quantity.toLocaleString(),
                    onChange: (value) => handleInlineQuantityChange(index, value),
                    color: "black",
                    isInput: true,
                    checkHasError: checkInlineValuesError
                },
                {
                    value: product.unitPrice === 0 ? '' : product.unitPrice.toLocaleString(),
                    onChange: (value) => handleInlineUnitPriceChange(index, value),
                    color: "black",
                    isInput: true,
                    checkHasError: checkInlineValuesError
                },
                // {value: product.unit ? product.unit : "-", color: "black"},
                {
                    value:
                        <div className={styles.Icons}>
                            <MdModeEdit
                                onClick={() => handleEditProduct(product, index)}
                                className={styles.EditIcon}
                            />
                            <FaRegTrashAlt
                                className={styles.TrashIcon}
                                onClick={() => handleDeleteProduct(index)}
                            />
                            {expandedRows.includes(index) ?
                                <IoIosArrowUp
                                    onClick={() => handleExpandRow(index)}
                                    style={{cursor: "pointer"}}
                                />
                                :
                                <IoIosArrowDown
                                    onClick={() => handleExpandRow(index)}
                                    style={{cursor: "pointer"}}
                                />
                            }
                        </div>
                }
            ];
        });

        const expansionData = receiptProducts.map((product, index) => {
            return (
                <div key={index} className={styles.ProductInfoExpansionContainer}>
                    <span className={styles.ProductInfoExpansion}>
                        <strong>اطلاعات تکمیلی کالا: </strong>
                        <br/>
                        {product.main_category} | {product.sub_category} | {product.brand}
                        {Object.entries(product.attributes).map(([name, value], attrIndex) => (
                            <span key={attrIndex}>
                                {attrIndex === 0 && ' | '}
                                {attrIndex > 0 && ' | '}{value}
                            </span>
                        ))}
                    </span>
                </div>
            );
        });

        return (
            <div className={styles.ReceiptProductsContainer}>
                {rows.length > 0 ?
                    <>
                        <div className={styles.ReceiptProductsTable}>
                            <CustomTable headerData={header} data={rows} styles={"desktop"}
                                         expansionData={expansionData} expandedRows={expandedRows}/>
                        </div>
                        <span className={styles.ExplanationText} style={{color: "var(--primary-dark-color"}}>* قیمت‌ها
                            به ریال نوشته شده‌اند.</span>
                    </>
                    :
                    <div className={styles.EmptyTableContainer}>
                        <img className={styles.EmptyTableImage} src={emptyTable} alt="Empty Table"/>
                        <span className={styles.ExplanationText}>برای پردازش این پیش‌فاکتور، لازم است کالاهای مربوط به
                            آن را اضافه کنید.</span>
                    </div>
                }
                <button className={styles.AddProduct} onClick={() => {
                    setPage(page + 1);
                    setSelectedProduct({});
                    setQuantity(0);
                    setUnit('');
                    setUnitPrice(0);
                }}>
                    اضافه کردن کالای جدید
                </button>
            </div>
        );
    }

    const handleSelectProduct = (product) => {
        if (product) {
            setSelectedProduct({
                value: product.id,
                label: product.name
            });
            setOption(2);
        }
    };

    const resetStoreErrors = () => {
        setShowStoreError(false);
        setShowPhoneError(false);
        setShowSuggestFileError(false);
    };

    const validateStoreInputs = () => {
        if (selectedStore === null) {
            setShowStoreError(true);
            return false;
        }
        if (storeNewPhone === null) {
            setShowPhoneError(true);
            return false;
        }
        return true;
    };

    const resetErrors = () => {
        setShowUnitPriceError(false);
        setShowQuantityError(false);
    };

    const validateInputs = () => {
        let isValid = true;
        if (unitPrice === 0) {
            setShowUnitPriceError(true);
            isValid = false;
        }
        if (quantity === 0) {
            setShowQuantityError(true);
            isValid = false;
        }
        return isValid;
    };

    const getOrCreateStore = async () => {
        if (Object.keys(storeObj).length !== 0) {
            return storeObj.id;
        } else {
            const response = await createStore(selectedStore.value, storeNewPhone);
            return response.data.id;
        }
    };

    const saveReceipt = async (storeId) => {
        if (selectedReceiptForEdit !== null) {
            await editReceipt(selectedReceiptForEdit.id, id, storeId, paymentMethod, additionalCosts);
            return selectedReceiptForEdit.id;
        } else if (isSuggestion) {
            const {data: {receipt_id}} = await addSuggestionReceipt(id, storeId, suggestedReceiptFile.data_uri, paymentMethod, additionalCosts);
            return receipt_id;
        } else {
            const {data: {receipt_id}} = await createReceipt(id, storeId, receiptFile.id, paymentMethod, additionalCosts);
            return receipt_id;
        }
    };

    const handleValidateStore = async () => {
        resetStoreErrors();

        if (validateStoreInputs()) {
            if (isSuggestion) {
                if (Object.values(suggestedReceiptFile).length === 0) {
                    setShowSuggestFileError(true);
                } else {
                    setPage(2);
                }
            } else {
                setPage(2);
            }
        }
    };

    const handleAddProduct = async () => {
        setLoading(true);
        resetErrors();
        const isPriceValid = validateInputs();
        let isProductValid = handleValidateProduct();

        if (isPriceValid && isProductValid) {
            if (unit === '') {
                await handleApproveProduct(quantity, null, unitPrice);
            } else {
                await handleApproveProduct(quantity, unit, unitPrice);
            }
            setPage(2);
            setReceiptEditIndex(null);
        }
        setLoading(false);
    };

    const handleClose = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        onClose();
    };

    const handleReceiptProcessing = async () => {
        setLoading(true);
        try {
            const storeId = await getOrCreateStore();
            const receiptId = await saveReceipt(storeId);

            await removeReceiptItems(receiptId);

            for (const product of receiptProducts) {
                await addReceiptItem(receiptId, product.id, product.unit, product.unitPrice, product.quantity);
            }
        } catch (error) {
            console.error('Error creating receipt or store:', error);
        } finally {
            setLoading(false);
            handleClose();
        }
    }

    let totalCost = 0;
    receiptProducts.map((product) => (
        totalCost += product.quantity * product.unitPrice
    ))
    totalCost += additionalCosts;
    const displayTotal = isNaN(totalCost) ? 0 : totalCost;

    return (
        <div className={`${styles.modalOverlay} ${showModal ? styles.show : ''}`}>
            <ToastContainer className={toast_style}/>

            <div id="modal" className={styles.modal}>
                <div className={styles.HeadingContainer}>
                    <MdClose className={styles.closeIcon} onClick={() => handleClose()}/>
                    <div className={styles.ReceiptNameAndChoice}>
                        {isSuggestion ?
                            <span className={styles.ReceiptName}>اضافه کردن پیش‌فاکتور پیشنهادی</span>
                            :
                            <span className={styles.ReceiptName}>پردازش {receiptName}</span>
                        }
                        {receiptFile && receiptFile.is_selected_by_user &&
                            <span className={styles.ClientChoiceIcon}>
                                <FaStar/>&nbsp;&nbsp;&nbsp;انتخاب مسئول خرید
                            </span>
                        }
                    </div>
                </div>

                <div className={styles.ReceiptProcessingModalContainer}>
                    {page === 1 &&
                        <div className={styles.RightSectionContainer}>
                            <div className={styles.StoreAndPaymentInfo}>
                                <span className={styles.heading}>
                                    اطلاعات فروشنده و پرداخت
                                </span>
                                {storesLoading ?
                                    <div className={styles.loading_container}>
                                        <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                      width='50px'
                                                      height='50px'/>
                                    </div>
                                    :
                                    <>
                                        <StoreNameField/>
                                        <div className={styles.FieldContainer}>
                                            <span className={styles.Subheading}>شماره تلفن:</span>
                                            {Object.keys(storeObj).length !== 0 ? (
                                                <input
                                                    className={styles.InputField}
                                                    value={storeObj.phone_number}
                                                    disabled={true}
                                                    ref={assignRef(1)}
                                                />
                                            ) : (
                                                <input
                                                    className={styles.InputField}
                                                    value={storeNewPhone}
                                                    onChange={handlePhoneChange}
                                                    ref={assignRef(1)}
                                                    onKeyDown={e => handleKeyDown(e, 1)}
                                                />
                                            )}
                                        </div>
                                        {showPhoneError && (
                                            <InlineError
                                                errorMessage={"باید یک شماره وارد کنید."}
                                                device={"desktop"}
                                            />
                                        )}
                                        <PaymentMethodField/>
                                    </>
                                }
                            </div>
                            <div className={styles.InquiryHistoryTimeLine}>
                                <span className={styles.heading}>گردش کار</span>
                                {isFetchingComments ?
                                    <div className={styles.loading_container}>
                                        <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                      width='50px'
                                                      height='50px'/>
                                    </div>
                                    :
                                    <TimeLine items={timelineItems}/>
                                }
                            </div>
                        </div>
                    }
                    {page === 2 &&
                        <div className={styles.RightSectionContainer}>
                            <div className={styles.ProductsInfo}>
                                <span className={styles.heading}>
                                    لیست کالا‌های پیش‌فاکتور
                                </span>
                                <RenderReceiptProducts/>
                            </div>
                            <div className={styles.AdditionalAndTotalContainer}>
                                <div className={styles.FieldContainer}>
                                    <span className={styles.AdditionalCostsSubheading}>
                                        سایر هزینه‌ها (ریال)
                                    </span>
                                    <input
                                        className={styles.AdditionalCostsInputField}
                                        value={additionalCosts.toLocaleString()}
                                        onChange={handleAdditionalCostsChange}
                                    />
                                </div>
                                <span className={styles.TotalPrice}>
                                    جمع کل: {displayTotal.toLocaleString()} ریال
                                </span>
                            </div>
                        </div>
                    }
                    {page === 3 &&
                        <div className={styles.RightSectionContainer}>
                            <div className={styles.ChooseProductContainer}>
                                <div className={styles.ChooseButtons}>
                                    <button className={styles.ChoiceButton} disabled={option === 1}
                                            onClick={() => {
                                                setOption(1);
                                            }}>
                                        جستجو در میان کالاها
                                    </button>
                                    <button className={styles.ChoiceButton} disabled={option === 2}
                                            onClick={() => {
                                                setOption(2);
                                            }}>
                                        اضافه کردن کالای جدید
                                    </button>
                                </div>
                                {option === 1 ?
                                    <ProductSearchComponent onProductSelected={handleSelectProduct}/>
                                    :
                                    (option === 2 || selectedProduct.value) ?
                                        <SelectProduct
                                            ref={selectProductRef}
                                            initiallySelectedProduct={selectedProduct}
                                            addProductToReceipt={addProductToReceipt}
                                        />
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    }
                    <div className={styles.LeftSectionContainer}>

                        {fileLoading ?
                            <div className={styles.loading_container}>
                                <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                              width='50px'
                                              height='50px'/>
                            </div>
                            :
                            memoizedReceiptImageSection
                        }
                    </div>
                </div>
                {page === 3 &&
                    <div className={styles.CostsInfo}>
                        <div className={styles.CostsFieldContainer}>
                            <span className={styles.Subheading}>
                                تعداد/وزن:
                                <span style={{color: "red"}}> *</span>
                            </span>
                            <input
                                className={`${styles.CostsInputField} ${showQuantityError ? `${styles.errorField} ${styles.shake}` : ''}`}
                                value={quantity.toLocaleString()}
                                onChange={handleQuantityChange}
                                ref={assignRef(0)}
                                onKeyDown={e => handleKeyDown(e, 0)}
                            />
                        </div>
                        <div className={styles.CostsFieldContainer}>
                            <span className={styles.Subheading}>
                                واحد:
                            </span>
                            <input
                                className={styles.CostsInputField}
                                value={unit}
                                onChange={handleUnitChange}
                                ref={assignRef(1)}
                                onKeyDown={e => handleKeyDown(e, 1)}
                            />
                        </div>
                        <div className={styles.CostsFieldContainer}>
                            <span className={styles.Subheading}>
                                قیمت واحد:
                                <span style={{color: "red"}}> *</span>
                            </span>
                            <input
                                className={`${styles.CostsInputField} ${showUnitPriceError ? `${styles.errorField} ${styles.shake}` : ''}`}
                                value={unitPrice.toLocaleString()}
                                onChange={handleUnitPriceChange}
                                ref={assignRef(2)}
                                onKeyDown={e => handleKeyDown(e, 2)}
                            />
                        </div>
                    </div>
                }

                <div className={styles.ActionButtons}>
                    {page === 1 &&
                        <button className={styles.NextButton} onClick={handleValidateStore}>
                            مرحله بعدی (اضافه کردن کالاها و قیمت‌ها)
                        </button>
                    }
                    {page === 2 &&
                        <button className={styles.NextButton} disabled={receiptProducts.length === 0}
                                onClick={() => {
                                    let hasError = false;
                                    for (let index = 0; index < receiptProducts.length; index++) {
                                        const item = receiptProducts[index];
                                        if (item.quantity === 0 || item.quantity === "") {
                                            hasError = true;
                                            setCheckInlineValuesError(true);
                                        }
                                        if (item.unitPrice === 0 || item.unitPrice === "") {
                                            hasError = true;
                                            setCheckInlineValuesError(true);
                                        }
                                    }
                                    if (!hasError) {
                                        setShowReceiptProcessingConfirmation(true);
                                    }
                                }}>
                            {isSuggestion ? "اتمام پردازش پیش‌فاکتور پیشنهادی" : "اتمام پردازش پیش‌فاکتور‌"}

                        </button>
                    }
                    {page === 3 &&
                        <button
                            className={styles.NextButton}
                            disabled={option === 1}
                            onClick={handleAddProduct}
                        >
                            {receiptEditIndex !== null ? "ویرایش کالا" : "اضافه کردن کالا"}
                        </button>
                    }
                    {page !== 1 &&
                        <button className={styles.BackButton} onClick={() => {
                            setPage(page - 1);
                            setReceiptEditIndex(null);
                        }}>
                            مرحله قبلی
                        </button>
                    }
                </div>

                {selectedPdf && <PDFPreview selectedPdf={selectedPdf} setSelectedPdf={setSelectedPdf}/>}
                {loading && <LoadingComponent isMobile={false}/>}
                {showReceiptProcessingConfirmation && (
                    <ConfirmationDialog
                        message={isSuggestion ? "آیا از اتمام پردازش پیش‌فاکتور پیشنهادی اطمینان دارید؟" : "آیا از اتمام پردازش این پیش‌فاکتور اطمینان دارید؟"}
                        onConfirm={handleReceiptProcessing}
                        onCancel={() => setShowReceiptProcessingConfirmation(false)}
                    />
                )}
            </div>

        </div>
    );
}

export default ReceiptProcessingModal;