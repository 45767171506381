import { useMobile } from "../../MobileContext";
import { useState } from "react";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import styles from "../../styles/PageLayout.module.css";
import React from 'react';
import DashboardDesktop from "../dashboard/DashboardDesktop";

const UserDashboard = () => {
    const isMobile = useMobile();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <> </>
            ) : (
                <DesktopHeader
                    role={"user"}
                    handleNotificationPanelToggle={() => setIsNotificationPanelOpen(!isNotificationPanelOpen)}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={`${styles.rightSectionContainer} ${isNotificationPanelOpen ? styles.blurred : ""}`}>
                    {isMobile ? (
                        <></>
                    ) : (
                        <DashboardDesktop role={"user"} />
                    )}
                </div>
                {isNotificationPanelOpen &&
                    <div className={styles.notificationContainer}>
                        <NotificationSection isOpen={isNotificationPanelOpen} />
                    </div>
                }
            </div>
        </div>
    )
}

export default UserDashboard;