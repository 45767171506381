import React, {createContext, useContext, useEffect, useState} from 'react';

const MobileContext = createContext();

export const MobileProvider = ({children}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 500);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return (
        <MobileContext.Provider value={isMobile}>
            {children}
        </MobileContext.Provider>
    );
};

export const useMobile = () => {
    return useContext(MobileContext);
};