import {useState, useEffect} from 'react';
import {getTopOrganizations} from '../../../APIs/auditor_dashboard';

const useOrganizationsRankingTable = (filters, setLoading) => {
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [organizationsData, setOrganizationsData] = useState([]);

    const organizationsRankingHeaders = [
        {value: 'رتبه'},
        {value: 'نام سازمان'},
        {value: 'تعداد درخواست'},
        {value: 'مجموع خریدها'},
    ];

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US').format(num);
    };

    const fetchTopOrganizations = async () => {
        try {
            setLoading(true);
            const response = await getTopOrganizations(filters);
            setData(response.data);
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching top orgs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTopOrganizations();
    }, [filters]);

    useEffect(() => {
        if (dataLoaded) {
            const rankingData = Object.keys(data).map((category, index) => {
                const {count, total_price_sum} = data[category];
                return [
                    {value: (index + 1).toString()},
                    {value: category},
                    {value: formatNumber(count)},
                    {value: `${formatNumber(total_price_sum)} ریال`},
                ];
            });

            setOrganizationsData(rankingData);
            setDataLoaded(false);
        }
    }, [data, dataLoaded]);

    return {organizationsRankingHeaders: organizationsRankingHeaders, organizationsRankingData: organizationsData};
};

export default useOrganizationsRankingTable;
