// fetchComments.js
import { getInquiryHistory } from "../../APIs/inquiry";

const fetchComments = async (id, setTimelineItems) => {
    try {
        const { data: { history } } = await getInquiryHistory(id);
        const transformedItems = history.reduce((acc, item, index, array) => {
            const newItem = {
                date: new Date(item.date).toLocaleDateString('fa-IR'),
                time: new Date(item.date).toLocaleTimeString('fa-IR', { hour: '2-digit', minute: '2-digit' }),
                content: item.action ?
                    item.action === 1 ? "ایجاد درخواست" :
                        item.action === 3 ? "ویرایش درخواست" :
                            item.action === 4 ? "تغییر وضعیت درخواست به در حال بررسی" :
                                item.action === 5 ? "تغییر وضعیت درخواست به بررسی شده" :
                                    item.action === 6 ? "تغییر وضعیت درخواست به خاتمه یافته" :
                                        item.action === 7 ? "تغییر وضعیت درخواست به لغو شده" :
                                            item.action === 8 ? "ارجاع درخواست به مسئول خرید" :
                                                "" : item.text,
                author: item.fullname ? item.fullname : '',
                isGreen: !!item.action,
                isAuditor: item.is_auditor,
            };
            acc.push(newItem);

            return acc;
        }, []);

        setTimelineItems(transformedItems);

    } catch (error) {
        console.error("Error fetching comments : ", error);
    } finally {
    }
};

export default fetchComments;


export const creatableSelectCustomStyles = {
    menu: (provided) => ({
        ...provided,
        maxHeight: 160, // Set the max height of the dropdown menu
        overflowY: 'auto', // Enable vertical scrolling
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: 160, // Ensure the menu list doesn't exceed the max height of the menu
    }),
};

export const creatableSelectCustomStylesStore = {
    menu: (provided) => ({
        ...provided,
        maxHeight: 100, // Set the max height of the dropdown menu
        overflowY: 'auto', // Enable vertical scrolling
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: 100, // Ensure the menu list doesn't exceed the max height of the menu
    }),
};
