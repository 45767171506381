import React, { useEffect, useState } from "react";
import LoadingComponent from "../Loading/Loading";
import stylesMobile from "./NotificationSectionMobile.module.css";
import stylesDesktop from "./NotificationSectionDesktop.module.css";
import { getInquiryEvents, getInquiryStatus, readAllInquiryEvents, readInquiryEvent } from "../../APIs/inquiry";
import { fetchCurrentUser } from "../../APIs/auth";
import { user_role_map } from "../../pages/auth/Consts";

const NotificationSection = ({ isOpen, device = "desktop" }) => {
    const styles = device === "desktop" ? stylesDesktop : stylesMobile;
    const [loading, setLoading] = useState(false);

    // State variables fetched in useEffect
    const [inquiryEvents, setInquiryEvents] = useState([]);
    const [readNotifications, setReadNotifications] = useState(new Set());

    // TODO(Low): inquiry statuses should be fetched from back-end
    // FIXME(Medium): duplicate with user inquiries page
    // TODO(High): when user clicks on read button, all the inquiry events should be read in that moment!
    const inquiryStatuses = {
        Statuses: [
            { id: 0, status: "درخواست‌های من" },
            { id: 1, status: "در انتظار پردازش" },
            { id: 2, status: "در حال بررسی" },
            { id: 3, status: "بررسی شده" },
            { id: 4, status: "خاتمه یافته" },
            { id: 5, status: "لغو شده" }
        ]
    };

    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const inquiryEventsData = await getInquiryEvents();
            setInquiryEvents(inquiryEventsData.data.events);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const handleReadAllNotifs = async () => {
        await readAllInquiryEvents();
        await fetchNotifications();
    };

    function getEventRelativeTime(absolute_time) {
        const MS_PER_SECOND = 1000;
        const MS_PER_MINUTE = 60 * MS_PER_SECOND;
        const MS_PER_HOUR = MS_PER_MINUTE * 60;
        const MS_PER_DAY = MS_PER_HOUR * 24;
        const MS_PER_WEEK = MS_PER_DAY * 7;
        const MS_PER_MONTH = MS_PER_WEEK * 4;
        const MS_PER_YEAR = MS_PER_MONTH * 12;

        const currentTime = new Date();
        const eventTime = new Date(absolute_time);
        const timeDifference = currentTime - eventTime;

        if (timeDifference < MS_PER_MINUTE) {
            const seconds = Math.floor(timeDifference / MS_PER_SECOND);
            return `${seconds} ثانیه پیش `;
        } else if (timeDifference < MS_PER_HOUR) {
            const minutes = Math.floor(timeDifference / MS_PER_MINUTE);
            return `${minutes} دقیقه پیش `;
        } else if (timeDifference < MS_PER_DAY) {
            const hours = Math.floor(timeDifference / MS_PER_HOUR);
            return `${hours} ساعت پیش `;
        } else if (timeDifference < MS_PER_WEEK) {
            const days = Math.floor(timeDifference / MS_PER_DAY);
            return `${days} روز پیش `;
        } else if (timeDifference < MS_PER_MONTH) {
            const weeks = Math.floor(timeDifference / MS_PER_WEEK);
            return `${weeks} هفته پیش `;
        } else if (timeDifference < MS_PER_YEAR) {
            const months = Math.floor(timeDifference / MS_PER_MONTH);
            return `${months} ماه پیش `;
        } else {
            const years = Math.floor(timeDifference / MS_PER_YEAR);
            return `${years} سال پیش `;
        }
    }

    return (
        <div className={`${styles.NotificationSectionContainer} ${isOpen ? styles.open : ''}`}>
            <div className={styles.NotificationSectionContent}>
                {device === "mobile" && inquiryEvents.length > 0 &&
                    <span className={styles.MobileHeading}>اطلاعیه‌ها</span>
                }
                {device === "mobile" && inquiryEvents.length === 0 &&
                    <span className={styles.MobileHeading} style={{ marginTop: '50%' }}>!در حال حاضر اطلاعیه‌ای وجود ندارد</span>
                }
                {inquiryEvents.slice().reverse().map((inquiryEvent) => (
                    <div
                        className={`${styles.NotificationItem} ${!inquiryEvent.has_read && !readNotifications.has(inquiryEvent.id) ? styles.unread : ''}`}
                        onClick={async () => {
                            const inquiryId = inquiryEvent.inquiry_id;

                            const user = await fetchCurrentUser();
                            const userRole = user_role_map[user.data.user.groups[0]];

                            try {
                                await readInquiryEvent(inquiryEvent.id);
                                setReadNotifications(prev => new Set(prev).add(inquiryEvent.id));

                                const inquiryStatus = await getInquiryStatus(inquiryId);
                                if (userRole === "Client") {
                                    if (inquiryStatus.data.status.status === 1) {
                                        window.location.href = `/user/edit-inquiry/${inquiryId}`;
                                    } else {
                                        window.location.href = `/user/inquiry/${inquiryId}`;
                                    }
                                } else {
                                    if (inquiryStatus.data.status.status === 5) {
                                        //     Do nothing for now!
                                    } else {
                                        window.location.href = `/auditor/inquiry/${inquiryId}`;
                                    }
                                }
                            } catch (error) {

                            } finally {
                            }
                        }}
                    >
                        {/*{*/}
                        {/*    inquiryEvent.action === 1 ? (*/}
                        {/*        <FaCirclePlus className={styles.ItemIcon}/>*/}
                        {/*    ) : inquiryEvent.action === 2 ? (*/}
                        {/*        <FaComment className={styles.ItemIcon}/>*/}
                        {/*    ) : inquiryEvent.action === 3 ? (*/}
                        {/*        <FaReceipt className={styles.ItemIcon}/>*/}
                        {/*    ) : inquiryEvent.action === 4 ? (*/}
                        {/*        <FaEdit className={styles.ItemIcon}/>*/}
                        {/*    ) : [5, 6].includes(inquiryEvent.action) ? (*/}
                        {/*        <FaCircleArrowLeft className={styles.ItemIcon}/>*/}
                        {/*    ) : inquiryEvent.action === 7 ? (*/}
                        {/*        <FaCheckCircle className={styles.ItemIcon}/>*/}
                        {/*    ) : inquiryEvent.action === 8 ? (*/}
                        {/*        <FaTimesCircle className={styles.ItemIcon}/>*/}
                        {/*    ) : null*/}
                        {/*}*/}
                        {
                            <div className={styles.ItemContent}>
                                <span className={styles.ItemHeader}>
                                    {inquiryEvent.display_message}
                                </span>
                                <span className={styles.ItemTime}>
                                    {getEventRelativeTime(inquiryEvent.date)}
                                </span>
                            </div>
                        }

                    </div>
                )
                )
                }
                {device === "mobile" &&
                    <button className={styles.MobileReadAllButton}
                        disabled={inquiryEvents.length === 0} onClick={handleReadAllNotifs}>همه خوانده
                        شوند</button>
                }
                {loading &&
                    <LoadingComponent isMobile={device === "mobile"} isNotification={true} />
                }
            </div>
        </div>
    );
};

export default NotificationSection;
