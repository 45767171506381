import styles from "./InlineError.module.css";
import React from "react";
import PropTypes from "prop-types";
import { AiOutlineExclamationCircle } from "@react-icons/all-files/ai/AiOutlineExclamationCircle";


const InlineError = ({ errorMessage, device }) => {
    return (
        <div className={styles.InlineErrorModuleContainer}>
            <AiOutlineExclamationCircle
                className={device === "mobile" ? styles.ErrorIconMobile : styles.ErrorIconDesktop} />
            <p className={device === "mobile" ? styles.InlineErrorContentMobile : styles.InlineErrorContentDesktop}>
                {errorMessage}
            </p>
        </div>
    );
}


InlineError.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    device: PropTypes.string.isRequired,
};

export default InlineError;