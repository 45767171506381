import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)(({ isProductTable, isStoreTable }) => ({
    borderRadius: isProductTable || isStoreTable ? '10px' : '0',
    border: isProductTable || isStoreTable ? '1px solid var(--grey-color)' : 'none',
    boxShadow: 'none',
}));

const getDeviceStyles = (device, isApprovedDoc) => {
    switch (device) {
        case 'mobile':
            return {
                fontSize: '3vw',
                padding: '8px 12px'
            };
        case 'tablet':
            return {
                fontSize: '2vw',
                padding: '10px 16px'
            };
        default: // desktop
            return {
                fontSize: isApprovedDoc ? '1.5vw' : '1vw',
                padding: '12px 16px'
            };
    }
};

const StyledTable = styled(Table)(({ device, isProductTable, isStoreTable }) => ({
    '& .MuiTableCell-root': {
        border: 'none',
        textAlign: 'center',
        ...getDeviceStyles(device, isProductTable || isStoreTable),
    }
}));

const StyledHeaderCell = styled(TableCell)(({ device }) => ({
    color: 'var(--primary-dark-color)',
    fontWeight: 'bold',
    fontFamily: 'IRANSans, serif',
    ...getDeviceStyles(device),
    // backgroundColor: '#e0f7fa' // Light teal background for header
}));

const StyledDataCell = styled(TableCell)(({ device, useFaNum = false }) => ({
    color: 'black',
    fontFamily: useFaNum ? 'IRANSansFaNum, serif' : 'IRANSans, serif',
    ...getDeviceStyles(device),
}));

const StyledTableRow = styled(TableRow)(({ theme, isEven, isProductTable }) => ({
    // backgroundColor: isEven ? '#f9f9f9' : '#ffffff', // Alternating row colors
    '&:hover': {
        backgroundColor: '#f1f1f1' // Hover effect
    },
    transition: 'background-color 0.3s ease',
    borderTop: isProductTable ? '1px solid var(--grey-color)' : 'none',
}));

const LastRow = styled(TableRow)(({ isProductTable }) => ({
    '& .left-cell': {
        fontWeight: 'bold',
        // textAlign: 'left',
    },
    '& .right-cell': {
        fontWeight: 'bold',
        // textAlign: 'left',
    },
    '&:hover': {
        backgroundColor: '#f1f1f1' // Hover effect
    },
    transition: 'background-color 0.3s ease',
    borderTop: isProductTable ? '1px solid var(--grey-color)' : 'none',
    backgroundColor: isProductTable ? '#f0f0f0' : 'inherit',
}));

const SimpleTable = ({ headers, data, columnWidths, lastRowData, secondLastRowData, device = 'desktop',
    isProductTable = false, isStoreTable = false, useFaNum = false }) => {

    if (!data || data.length === 0 || !headers || headers.length === 0) {
        return <></>;
    }

    const totalWidth = columnWidths.reduce((total, width) => total + width, 0);
    if (totalWidth !== 100) {
        console.warn('Column widths do not sum to 100%');
    }

    return (
        <StyledTableContainer component={Paper} isProductTable={isProductTable} isStoreTable={isStoreTable}>
            <StyledTable device={device} isProductTable={isProductTable} isStoreTable={isStoreTable}>
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <StyledHeaderCell
                                key={index}
                                style={{
                                    width: `${columnWidths[index]}%`,
                                    backgroundColor: isProductTable ? '#f1f1f1' : 'none'
                                }}
                                device={device}
                            >
                                {header.value}
                            </StyledHeaderCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => (
                        <StyledTableRow key={rowIndex} isEven={rowIndex % 2 === 0}
                            isProductTable={isProductTable}>
                            {row.map((cell, cellIndex) => (
                                <StyledDataCell
                                    key={cellIndex}
                                    style={{ width: `${columnWidths[cellIndex]}%` }}
                                    device={device}
                                    useFaNum={useFaNum}
                                >
                                    {cell.value}
                                </StyledDataCell>
                            ))}
                        </StyledTableRow>
                    ))}
                    {secondLastRowData && (
                        <LastRow>
                            {device === "desktop" ?
                                <StyledDataCell colSpan={3} />
                                :
                                <StyledDataCell colSpan={2} />
                            }
                            <StyledDataCell className="right-cell" colSpan={1} device={device}>
                                {secondLastRowData.right}
                            </StyledDataCell>
                            <StyledDataCell className="left-cell" colSpan={1} device={device}>
                                {secondLastRowData.left}
                            </StyledDataCell>
                        </LastRow>
                    )}
                    {lastRowData && (
                        <LastRow style={{
                            borderBottom: isProductTable ? "none" : "1px solid var(--grey-color)"
                        }}
                            isProductTable={isProductTable}>
                            {isProductTable ? (
                                <StyledDataCell
                                    colSpan={5}
                                    device={device}
                                    style={{ textAlign: 'right' }}
                                >
                                    {`${lastRowData.right}`}
                                </StyledDataCell>
                            ) : (
                                <>
                                    <StyledDataCell
                                        colSpan={device === "desktop" ? 3 : 2}
                                    />
                                    <StyledDataCell
                                        className="right-cell"
                                        colSpan={1}
                                        device={device}
                                    >
                                        {lastRowData.right}
                                    </StyledDataCell>
                                    <StyledDataCell
                                        className="left-cell"
                                        colSpan={1}
                                        device={device}
                                    >
                                        {lastRowData.left}
                                    </StyledDataCell>
                                </>
                            )}
                        </LastRow>
                    )}
                </TableBody>
            </StyledTable>
        </StyledTableContainer >
    );
};

export default SimpleTable;
