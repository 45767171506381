import { NavLink } from "react-router-dom";
import styles from "./MobileNavbar.module.css";

const Navbar = ({ items }) => {
    return (
        <nav className={styles.NavbarContainer}>
            <div className={styles.navElements}>
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>
                            <NavLink to={item.path}>{item.label}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>

    );
}

export default Navbar
