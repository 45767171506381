import { useMobile } from "../../MobileContext";
import UserHeaderMobile from "./mobile/UserHeader";
import UserInquiryMobile from "./mobile/UserInquiryMobile";
import InquiryDesktop from "../common/InquiryDesktop";
import { useState } from "react";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import styles from "../../styles/PageLayout.module.css";
import React from 'react';

const UserInquiry = () => {
    const isMobile = useMobile();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <UserHeaderMobile />
            ) : (
                <DesktopHeader
                    role={"user"}
                    handleNotificationPanelToggle={() => setIsNotificationPanelOpen(!isNotificationPanelOpen)}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={`${styles.rightSectionContainer} ${isNotificationPanelOpen ? styles.blurred : ""}`}>
                    {isMobile ? (
                        <UserInquiryMobile />
                    ) : (
                        <InquiryDesktop />
                    )}
                </div>
                {isNotificationPanelOpen &&
                    <div className={styles.notificationContainer}>
                        <NotificationSection isOpen={isNotificationPanelOpen} />
                    </div>
                }
            </div>
        </div>
    )
}

export default UserInquiry;