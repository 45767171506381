import React from 'react';
import {useMemo} from 'react';
import styles from "../styles/auditor/desktop/AuditorInquiry.module.css";

const useUniqueProducts = (selectedProducts) => {
    return useMemo(() => {
        return selectedProducts.filter((product, index, self) =>
            index === self.findIndex((p) => p.id === product.id)
        );
    }, [selectedProducts]);
};

const InquiryProductsInfo = ({selectedProducts}) => {
    const uniqueProducts = useUniqueProducts(selectedProducts);

    return (
        uniqueProducts.length === 0 ? (
            <span className={styles.ExplanationTextFull}>هنوز هیچ کالایی انتخاب نشده است.</span>
        ) : (
            uniqueProducts.map((selectedProduct) => (
                <span key={selectedProduct.id} className={styles.ProductDetailText}>
                    {selectedProduct.name} | {selectedProduct.main_category} | {selectedProduct.sub_category} | {selectedProduct.brand}
                    {Object.entries(selectedProduct.attributes).map(([name, value], index) => (
                        <span key={index}>
                            {index === 0 && ' | '}
                            {index > 0 && ' | '}{value}
                        </span>
                    ))}
                </span>
            ))
        )
    );
};

export default InquiryProductsInfo;