import axios, {isCancel} from 'axios';

const axiosInstance = axios.create(
    {
        baseURL: process.env.REACT_APP_BASE_URL,
        responseType: "json",
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json"
        }
    }
);

const refreshTokenAxios = axios.create(
    {
        baseURL: process.env.REACT_APP_BASE_URL,
        responseType: "json",
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json"
        }
    }
);

axiosInstance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem("access_token");
        const excludedUrls = ['user/add-user/'];

        if (accessToken && !excludedUrls.includes(config.url)) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check for aborted requests first
        if (isCancel(error)) {
            return Promise.reject(error);
        }

        // Check for network errors or other errors without a response
        if (!error.response) {
            console.error("Network error or server is unreachable.");
            alert("خطای شبکه یا عدم دسترسی به سرور ایران‌تدارکات! لطفاً اتصال اینترنت خود را بررسی کنید.");
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refresh_token');
                const response = await refreshTokenAxios.post('token/refresh/', {refresh: refreshToken});

                const token = response.data.access;

                localStorage.setItem('access_token', token);
                localStorage.setItem('refresh_token', response.data.refresh);

                originalRequest.headers.Authorization = `Bearer ${token}`;

                return axios(originalRequest);
            } catch (refreshError) {
                console.error("Error refreshing the token.");
                // Optionally, redirect to login page or show a message
                // window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
