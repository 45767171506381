import loading_styles from "./Loading.module.css";
import ReactLoading from "react-loading";
import React from "react";
import ProgressBar from "../ProgressBar/ProgressBar";

const LoadingComponent = ({ isMobile, text = "در حال بارگذاری ...", isNotification = false, progress = null }) => {
    let containerStyle, textStyle;

    if (isMobile) {
        containerStyle = loading_styles.loadingContainerMobile;
        textStyle = loading_styles.loadingTextMobile;
    } else {
        if (isNotification) {
            containerStyle = loading_styles.loadingContainerNotificationDesktop;
            textStyle = loading_styles.loadingTextNotificationDesktop;
        } else {
            containerStyle = loading_styles.loadingContainerDesktop;
            textStyle = loading_styles.loadingTextDesktop;
        }
    }

    return (
        <div className={containerStyle}>
            <ReactLoading type={'spin'} />
            <p className={textStyle}>
                {text}
            </p>
            {progress && <ProgressBar progress={progress} />}
        </div>
    );
};

export default LoadingComponent;
