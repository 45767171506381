import axios from "./base";

export function createReceipt(inquiry_id, store_id, photo_id, payment_method, additional_costs) {
    return axios.post(`receipt/create/`, {
        inquiry_id: inquiry_id,
        store_id: store_id,
        photo_id: photo_id,
        payment_method: payment_method,
        additional_costs: additional_costs
    });
}

export function editReceipt(receipt_id, inquiry_id, store_id, payment_method, additional_costs) {
    return axios.post(`receipt/edit/`, {
        receipt_id: receipt_id,
        inquiry_id: inquiry_id,
        store_id: store_id,
        payment_method: payment_method,
        additional_costs: additional_costs
    });
}

export function addSuggestionReceipt(inquiry_id, store_id, photo, payment_method, additional_costs) {
    return axios.post(`receipt/add-suggestion/`, {
        inquiry_id: inquiry_id,
        store_id: store_id,
        photo: photo,
        payment_method: payment_method,
        additional_costs: additional_costs
    });
}

export function addResult(inquiry_id, approved_receipt) {
    return axios.post(`receipt/add-result/`, {
        inquiry_id: inquiry_id,
        approved_receipt: approved_receipt,
    });
}

export function completeReceiptsProcessing(inquiry_id) {
    return axios.post(`receipt/complete-receipts-processing/`, { inquiry_id: inquiry_id })
}

export function isReceiptProcessedForPhoto(photo_id) {
    return axios.get(`receipt/is-receipt-processed/${photo_id}/`);
}

export function removeReceiptItems(receipt_id) {
    return axios.post(`receipt/remove-items/`, { receipt_id: receipt_id });
}