import styles from "./StatusNavbar.module.css";
import React from "react";

const StatusNavbar = ({inquiryStatus, handleInquirySelect, isAuditor, inquiryCounts}) => {
    const handleSelectStatus = (status) => {
        handleInquirySelect(status);
    };

    return (
        <nav className={styles.Navbar}>
            <ul className={styles.NavbarList}>
                <li onClick={() => handleSelectStatus(0)}
                    className={inquiryStatus === 0 ? styles.selected : ""}>
                    کارتابل من
                    ({inquiryCounts.Cartable})
                </li>
                <li onClick={() => handleSelectStatus(1)}
                    className={inquiryStatus === 1 ? styles.selected : ""}>
                    در انتظار پردازش
                    ({inquiryCounts.Pending})
                </li>
                <li onClick={() => handleSelectStatus(2)}
                    className={inquiryStatus === 2 ? styles.selected : ""}>
                    در حال بررسی
                    ({inquiryCounts.Checking})
                </li>
                <li onClick={() => handleSelectStatus(3)}
                    className={inquiryStatus === 3 ? styles.selected : ""}>
                    بررسی شده
                    ({inquiryCounts.Checked})
                </li>
                <li onClick={() => handleSelectStatus(4)}
                    className={inquiryStatus === 4 ? styles.selected : ""}>
                    خاتمه یافته
                    ({inquiryCounts.Approved})
                </li>
                {!isAuditor &&
                    <li onClick={() => handleSelectStatus(5)}
                        className={inquiryStatus === 5 ? styles.selected : ""}>
                        لغو شده
                        ({inquiryCounts.Cancelled})
                    </li>
                }
            </ul>
        </nav>
    );
};

export default StatusNavbar;