import UserInquiries from "./pages/user/UserInquiries";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MobileProvider} from "./MobileContext";
import CheckAuth from "./components/CheckAuth";
import NewInquiry from "./pages/user/NewInquiry";
import UserInquiry from "./pages/user/UserInquiry";
import AuditorHome from "./pages/auditor/AuditorHome";
import AuditorInquiry from "./pages/auditor/AuditorInquiry";
import AuditorDashboard from "./pages/auditor/Dashboard";
import EditInquiry from "./pages/user/EditInquiry";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Home from "./pages/Home";
import Logout from "./pages/auth/Logout";
import FinancialHome from "./pages/financial_manager/FinancialHome";
import FinancialInquiry from "./pages/financial_manager/FinancialInquiry";
import Miscellaneous from "./pages/common/Miscellaneous";
import React from 'react';
import ManagerDashboard from "./pages/manager/Dashboard";
import UserDashboard from "./pages/user/Dashboard";
import ManagerHome from "./pages/manager/ManagerHome";
import ManagerInquiry from "./pages/manager/ManagerInquiry";

// user
const ProtectedUserHome = CheckAuth(UserInquiries, ['Client', 'Admin']);
const ProtectedNewRequest = CheckAuth(NewInquiry, ['Client', 'Admin']);
const ProtectedInquiry = CheckAuth(UserInquiry, ['Client', 'Admin']);
const ProtectedEditInquiry = CheckAuth(EditInquiry, ['Client', 'Admin']);
const ProtectedUserDashboard = CheckAuth(UserDashboard, ['Client', 'Admin']);

// auditor
const ProtectedAuditorHome = CheckAuth(AuditorHome, ['Auditor', 'CEO', 'Admin']);
const ProtectedAuditorInquiry = CheckAuth(AuditorInquiry, ['Auditor', 'CEO', 'Admin']);
const ProtectedAuditorDashboard = CheckAuth(AuditorDashboard, ['Auditor', 'CEO', 'Admin']);

// financial manager
const ProtectedFinancialManagerHome = CheckAuth(FinancialHome, ['FinancialManager', 'Admin']);
const ProtectedFinancialManagerInquiry = CheckAuth(FinancialInquiry, ['FinancialManager', 'Admin']);

// manager
const ProtectedManagerDashboard = CheckAuth(ManagerDashboard, ["Manager", "Admin"]);
const ProtectedManagerHome = CheckAuth(ManagerHome, ["Manager", "Admin"]);
const ProtectedManagerInquiry = CheckAuth(ManagerInquiry, ["Manager", "Admin"]);

// Miscellaneous
const ProtectedMiscellaneous = CheckAuth(Miscellaneous,
    ['Client', 'Auditor', 'Manager', 'FinancialManager', 'Admin', 'CEO']);

function App() {
    return (
        <MobileProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    <Route path="/user" element={<ProtectedUserHome/>}/>
                    <Route path="/user/new-inquiry" element={<ProtectedNewRequest/>}/>
                    <Route path="/user/inquiry/:id" element={<ProtectedInquiry/>}/>
                    <Route path="/user/edit-inquiry/:id" element={<ProtectedEditInquiry/>}/>
                    <Route path="/user/dashboard" element={<ProtectedUserDashboard/>}/>

                    <Route path="/auditor" element={<ProtectedAuditorHome/>}/>
                    <Route path="/auditor/inquiry/:id" element={<ProtectedAuditorInquiry/>}/>
                    <Route path="/auditor/dashboard" element={<ProtectedAuditorDashboard/>}/>

                    <Route path="/manager" element={<ProtectedManagerHome/>}/>
                    <Route path="/manager/inquiry/:id" element={<ProtectedManagerInquiry/>}/>
                    <Route path="/manager/dashboard" element={<ProtectedManagerDashboard/>}/>

                    <Route path="/financial-manager" element={<ProtectedFinancialManagerHome/>}/>
                    <Route path="/financial-manager/inquiry/:id" element={<ProtectedFinancialManagerInquiry/>}/>

                    {/*This is actually for unauthorized access*/}
                    <Route path="/404" element={<ProtectedMiscellaneous type={"not-found"}/>}/>
                    <Route path="*" element={<ProtectedMiscellaneous type={"not-found"}/>}/>
                    <Route path="/coming-soon" element={<ProtectedMiscellaneous type={"coming-soon"}/>}/>
                    <Route path="/500" element={<ProtectedMiscellaneous type={"500"}/>}/>
                </Routes>
            </BrowserRouter>
        </MobileProvider>
    );
}

export default App;
