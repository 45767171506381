import {useState, useCallback} from 'react';
import {getInquiry, getInquiryReceipts} from "../APIs/inquiry";
import SimpleTable from "../components/SimpleTable/SimpleTable";

const useFetchInquiry = (id) => {
    const [inquiryLoading, setInquiryLoading] = useState(false);
    const [receiptsLoading, setReceiptsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [inquiryDetail, setInquiryDetail] = useState(null);
    const [receiptsDetail, setReceiptsDetail] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [areReceiptsProcessed, setAreReceiptsProcessed] = useState(false);
    const [expansionData, setExpansionData] = useState([]);

    const fetchInquiry = useCallback(async () => {
        setInquiryLoading(true);
        try {
            const inquiryData = await getInquiry(id);
            setInquiryDetail(inquiryData.data);
            setAreReceiptsProcessed(inquiryData.data.is_inquiry_processed);
        } catch (error) {
            console.error('Error fetching inquiry:', error);
            setError(true);
        } finally {
            setInquiryLoading(false);
        }
    }, [id]);

    const fetchReceipts = useCallback(async () => {
        setReceiptsLoading(true);
        try {
            const receiptData = await getInquiryReceipts(id);
            setReceiptsDetail(receiptData.data.receipts);

            // set products
            const products = receiptData.data.receipts.flatMap(entry => entry.items.map(item => item.product));
            setSelectedProducts(products);

            // Generate expansion data
            const newExpansionData = receiptData.data.receipts.map((receipt) => {
                const expandedHeader = [
                    {value: "نام کالا"},
                    {value: "تعداد/وزن"},
                    {value: "واحد"},
                    {value: "قیمت واحد"},
                    {value: "قیمت کل"}
                ];

                const expandedData = receipt.items.map(item => [
                    {value: item.product.name},
                    {value: item.quantity.toLocaleString()},
                    {value: item.unit ? item.unit : "-"},
                    {value: item.unit_price.toLocaleString()},
                    {value: `${(item.unit_price * item.quantity).toLocaleString()} ریال`},
                ]);

                const secondLastRowData = {
                    right: `سایر هزینه‌ها: `,
                    left: `${receipt.additional_costs.toLocaleString()} ریال`
                };

                const lastRowData = {
                    right: `جمع کل: `,
                    left: `${receipt.total_price.toLocaleString()} ریال`
                };

                return (
                    <SimpleTable
                        headers={expandedHeader}
                        data={expandedData}
                        columnWidths={[20, 20, 20, 20, 20]}
                        secondLastRowData={secondLastRowData}
                        lastRowData={lastRowData}
                    />
                );
            });

            setExpansionData(newExpansionData);
        } catch (error) {
            console.error('Error fetching inquiry receipts:', error);
            setError(true);
        } finally {
            setReceiptsLoading(false);
        }
    }, [id, inquiryDetail]);

    const fetchAll = useCallback(async () => {
        await fetchInquiry();
        await fetchReceipts();
    }, [fetchInquiry, fetchReceipts]);

    return {
        inquiryLoading,
        receiptsLoading,
        error,
        inquiryDetail,
        receiptsDetail,
        selectedProducts,
        areReceiptsProcessed,
        expansionData,
        fetchInquiry,
        fetchReceipts,
        fetchAll
    };
};

export default useFetchInquiry;