import { useState, useEffect } from 'react';
import { getTotalSpending } from "../../../APIs/auditor_dashboard";

const useTotalSpending = (filters, setLoading) => {
    const [data, setData] = useState(0);

    const fetchTotalSpending = async () => {
        try {
            setLoading(true);
            const response = await getTotalSpending(filters);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching total spending:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTotalSpending();
    }, [filters]);

    return data;
};

export default useTotalSpending;
