import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {fetchCurrentUser} from '../APIs/auth';
import {user_role_map} from "../pages/auth/Consts";

function getExpirationTime(token) {
    const payload = token.split('.')[1];
    const decodedPayload = atob(payload);
    const parsedPayload = JSON.parse(decodedPayload);
    return parsedPayload.exp;
}

const CheckAuth = (Component, allowedRoles) => {
    const AuthComponent = (props) => {
        const [authState, setAuthState] = useState('pending');

        useEffect(() => {
            const checkAuth = async () => {
                try {
                    const token = localStorage.getItem('access_token') || null;
                    const refreshToken = localStorage.getItem('refresh_token');

                    if (!token) {
                        setAuthState('unauthenticated');
                        return;
                    }

                    const user = await fetchCurrentUser();
                    const userRole = user_role_map[user.data.user.groups[0]];

                    const expirationTime = getExpirationTime(token);
                    const currentTimestamp = Math.floor(Date.now() / 1000);

                    if (expirationTime < currentTimestamp) {
                        try {
                            const response = await refreshToken(refreshToken);
                            localStorage.setItem('access_token', response.data.access);
                            localStorage.setItem('refresh_token', response.data.refresh);
                            setAuthState('authenticated');
                        } catch (refreshError) {
                            console.error("Error refreshing token:", refreshError);
                            setAuthState('unauthenticated');
                        }
                    } else {
                        if (allowedRoles.includes(userRole)) {
                            setAuthState('authenticated');
                        } else {
                            setAuthState('unauthorized');
                        }
                    }
                } catch (error) {
                    console.error("Error during authentication check:", error);
                    setAuthState('unauthenticated');
                }
            };

            checkAuth();
        }, []);

        if (authState === 'pending') {
            // maybe loading component
            return null;
        } else if (authState === 'authenticated') {
            return <Component {...props} />;
        } else if (authState === 'unauthorized') {
            return <Navigate replace to="/404"/>;
        } else {
            return <Navigate replace to="/login"/>;
        }
    };

    return AuthComponent;
};

export default CheckAuth;
