import {logout} from "../../APIs/auth";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleLogout = async () => {
            try {
                await logout(localStorage.getItem('refresh_token'));
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.clear();
            } catch (error) {
                console.error('Error occurred during logout:', error);
            } finally {
                navigate(`/login`);
            }
        };

        handleLogout();
    }, []);

    return null;
}

export default Logout;