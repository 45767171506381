import React from 'react';
import './SuccessDialog.css';
import { IoCloudDone } from "@react-icons/all-files/io5/IoCloudDone";

const SuccessDialog = ({ message, details, onConfirm, buttonText }) => {
    return (
        <div className="success-dialog">
            <div className="success-content">
                <IoCloudDone className='success-icon' />
                <p className="success-message">{message}</p>
                <p className='success-detail-message'>{details}</p>
                <button className="success-button confirm" onClick={onConfirm}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default SuccessDialog;