import { useMobile } from "../../MobileContext";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import styles from "../../styles/PageLayout.module.css";
import React from 'react';
import InquiriesDesktop from "../common/InquiriesDesktop";

const ManagerHome = () => {
    const isMobile = useMobile();

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <> </>
            ) : (
                <DesktopHeader
                    role={"manager"}
                    showNotification={false}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={styles.rightSectionContainer}>
                    {isMobile ? (
                        <> </>
                    ) : (
                        <InquiriesDesktop userRole={"manager"} />
                    )}
                </div>

            </div>
        </div>
    )
}

export default ManagerHome;