import {useMobile} from "../../MobileContext";
import AuditorInquiryDesktop from "./desktop/AuditorInquiry";
import {useState} from "react";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import styles from "../../styles/PageLayout.module.css";
import React from 'react';

const AuditorInquiry = () => {
    const isMobile = useMobile();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <> </>
            ) : (
                <DesktopHeader
                    role={"auditor"}
                    handleNotificationPanelToggle={() => setIsNotificationPanelOpen(!isNotificationPanelOpen)}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={`${styles.rightSectionContainer} ${isNotificationPanelOpen ? styles.blurred : ""}`}>
                    {isMobile ? (
                        <> </>
                    ) : (
                        <AuditorInquiryDesktop/>
                    )}
                </div>
                {isNotificationPanelOpen &&
                    <div className={styles.notificationContainer}>
                        <NotificationSection isOpen={isNotificationPanelOpen}/>
                    </div>
                }
            </div>
        </div>
    )
}

export default AuditorInquiry;