import { useState, useEffect } from 'react';
import { getTopMainCategories } from '../../../APIs/auditor_dashboard';

const useProductMainCategoryRankingTable = (filters, setLoading) => {
    const [data, setData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [productRankingData, setProductRankingData] = useState([]);

    const productRankingHeaders = [
        { value: 'رتبه' },
        { value: 'نام گروه' },
        { value: 'تعداد درخواست' },
        { value: 'مجموع خریدها' },
    ];

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US').format(num);
    };

    const fetchTopMainCategories = async () => {
        try {
            setLoading(true);
            const response = await getTopMainCategories(filters);
            setData(response.data);
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching top main categories:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTopMainCategories();
    }, [filters]);

    useEffect(() => {
        if (dataLoaded) {
            const rankingData = Object.keys(data).map((category, index) => {
                const { count, total_price_sum } = data[category];
                return [
                    { value: (index + 1).toString() },
                    { value: category },
                    { value: formatNumber(count) },
                    { value: `${formatNumber(total_price_sum)} ریال` },
                ];
            });

            setProductRankingData(rankingData);
            setDataLoaded(false);
        }
    }, [data, dataLoaded]);

    return { productMainCategoryRankingHeaders: productRankingHeaders, productMainCategoryRankingData: productRankingData };
};

export default useProductMainCategoryRankingTable;
