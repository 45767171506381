import {useEffect, useState} from 'react';
import {Chart} from 'chart.js/auto';
import {getStoreByMainCategoryDistribution} from "../../../APIs/auditor_dashboard";

const useStoreByMainCategoryPieChart = (chartRef, chartInstanceRef, filters, main_category, setLoading) => {
    const [data, setData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    const fetchStoreByMainCategory = async () => {
        try {
            setLoading(true);
            const response = await getStoreByMainCategoryDistribution(filters, main_category);
            const responseData = response.data;
            setData(responseData);

            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching store by product main category:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (chartRef.current && main_category !== null) {
            fetchStoreByMainCategory();
        }
    }, [chartRef, filters, main_category]);

    useEffect(() => {
        if (dataLoaded) {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
            const ctx = chartRef.current.getContext("2d");

            Chart.defaults.font.family = 'IRANSansFaNum';

            const labels = Object.keys(data);
            const chartData = Object.values(data);

            chartInstanceRef.current = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [{
                        data: chartData,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'right',
                            labels: {
                                font: {
                                    size: 12
                                },
                                padding: 15,
                                usePointStyle: true,
                            }
                        },
                        tooltip: {
                            enabled: true,
                            backgroundColor: '#1A237E',
                            titleColor: 'white',
                            bodyColor: 'white',
                            displayColors: false,
                            intersect: false,
                            callbacks: {
                                label: function (tooltipItem) {
                                    const dataset = tooltipItem.chart.data.datasets[0];
                                    const total = dataset.data.reduce((acc, value) => acc + value, 0);
                                    const currentValue = dataset.data[tooltipItem.dataIndex];
                                    const percentage = ((currentValue / total) * 100).toFixed(2);
                                    return `مقدار: ${tooltipItem.formattedValue} (${percentage}%)`;
                                }
                            }
                        },
                    }
                },
            });

            setDataLoaded(false);
        }

    }, [data, dataLoaded, chartRef, chartInstanceRef]);

};

export default useStoreByMainCategoryPieChart;
