import { useState, useEffect } from 'react';
import { getTotalProfit } from "../../../APIs/auditor_dashboard";

const useTotalProfit = (filters, setLoading) => {
    const [data, setData] = useState(0);

    const fetchTotalProfit = async () => {
        try {
            setLoading(true);
            const response = await getTotalProfit(filters);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching total profit:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTotalProfit();
    }, [filters]);

    return data;
};

export default useTotalProfit;
