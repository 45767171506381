import React from "react";
import {BsBell} from "@react-icons/all-files/bs/BsBell";
import styles from "./InquiryUpdates.module.css"

const InquiryUpdatesBell = React.memo(({ count, isAuditor = false }) => {
    if (count === 0) return null;

    return (
        <div className={isAuditor ? styles.inquiryUpdatesTable : styles.inquiryUpdates}>
            <span className={styles.updateCount}>{count}</span>
            <BsBell className={styles.bellIcon} />
        </div>
    );
});


export default InquiryUpdatesBell;