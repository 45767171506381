import React, { useEffect, useState } from "react";
import ComingSoonImg from "../../assets/images/ComingSoon.svg"
import NotFoundImg from "../../assets/images/NotFound.svg"
import Error500Img from "../../assets/images/Error500.svg"
import { useMobile } from "../../MobileContext";
import UserHeaderMobile from "../user/mobile/UserHeader";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import styles from "../../styles/PageLayout.module.css";
import { fetchCurrentUser } from '../../APIs/auth';
import { user_role_map } from "../auth/Consts";



const Miscellaneous = ({ type }) => {
    const isMobile = useMobile();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
    const [role, setRole] = useState("user");

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await fetchCurrentUser();
                const userRole = user_role_map[user.data.user.groups[0]];
                setRole(userRole);
            } catch (error) {
                setRole("invalid");
            }
        }

        fetchUser();
    }, []);

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <UserHeaderMobile />
            ) : (
                <DesktopHeader
                    role={role}
                    handleNotificationPanelToggle={() => setIsNotificationPanelOpen(!isNotificationPanelOpen)}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={styles.rightSectionContainer}>
                    {type === "coming-soon" ?
                        <div className={styles.MiscellaneousPageContainer}>
                            <img className={styles.Image} src={ComingSoonImg} />
                            <span className={styles.Text}>این بخش به‌زودی اضافه خواهد شد!</span>
                        </div>
                        : type === "not-found" ?
                            <div className={styles.MiscellaneousPageContainer}>
                                <img className={styles.Image} src={NotFoundImg} />
                                <span className={styles.Heading}>خطا</span>
                                <span className={styles.Text}>صفحه‌ای که به دنبال آن هستید در سامانه وجود ندارد.</span>
                            </div>
                            : type === "500" ?
                                <div className={styles.MiscellaneousPageContainer}>
                                    <img className={styles.Image} src={Error500Img} />
                                    <span className={styles.Heading}>خطا</span>
                                    <span className={styles.Text}>خطایی در سامانه به وجود آمده است! لطفا دوباره تلاش کنید.</span>
                                </div>
                                : <></>
                    }

                </div>
                {isNotificationPanelOpen &&
                    <div className={styles.notificationContainer}>
                        <NotificationSection isOpen={isNotificationPanelOpen} />
                    </div>
                }
            </div>
        </div >
    )
}

export default Miscellaneous;