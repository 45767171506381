import React, { useState } from 'react';
import styles from "./UploadReceipt.module.css";
import {FaCloudUploadAlt} from "@react-icons/all-files/fa/FaCloudUploadAlt";
import InlineError from "../InlineError/InlineError";
import { compressImage } from "../CompressImage";
import LoadingComponent from "../Loading/Loading";

const UploadReceipt = ({ onImageSelected, showSuggestFileError }) => {
    const [showFileErrorMessage, setShowFileErrorMessage] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);

    const handleFileButtonClick = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp',
            'image/webp', 'image/svg+xml', 'application/pdf'];
        setShowFileErrorMessage(false);

        if (file && acceptedImageTypes.includes(file.type)) {
            setFileLoading(true);
            const reader = new FileReader();
            reader.onload = (event) => {
                const uri = event.target.result;
                if (file.type === 'application/pdf') {
                    handleSelectedImage(uri, "pdf");
                    setFileLoading(false);
                } else {
                    compressImage(uri, 0.7, (error, compressedUri) => {
                        if (error) {
                            console.error("Compression error:", error);
                            handleSelectedImage(uri, "image");
                        } else {
                            handleSelectedImage(compressedUri, "image");
                        }
                        setFileLoading(false);
                    });
                }
            };
            reader.readAsDataURL(file);
        } else {
            setShowFileErrorMessage(true);
        }
    };

    const handleSelectedImage = (data_uri, file_type) => {
        onImageSelected({
            data_uri: data_uri,
            file_format: file_type
        });
    };

    return (
        <div className={styles.newInquiryFileUpload}>
            <h2 className={styles.heading}>بارگذاری فاکتور پیشنهادی</h2>
            <div className={styles.uploadBox} onClick={handleFileButtonClick}>
                <input type="file" id="fileInput" className={styles.uploadInput} onChange={handleFileChange} />
                <FaCloudUploadAlt className={styles.cloudIcon} />
                <span className={styles.uploadText}>
                    اضافه کردن فایل
                    <span style={{ color: "red" }}> *</span>
                </span>
            </div>
            {showSuggestFileError &&
                <InlineError errorMessage="یک فاکتور در این قسمت آپلود کنید." device="desktop" />
            }
            {showFileErrorMessage &&
                <InlineError errorMessage="فرمت فایل بارگذاری شده پشتیبانی نمی‌شود." device="desktop" />
            }
            {fileLoading && <LoadingComponent isMobile={false} />}
        </div>
    );
};

export default UploadReceipt;