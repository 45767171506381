import { useMobile } from "../../MobileContext";
import UserHeaderMobile from "./mobile/UserHeader";
import NewInquiryModule from "./NewInquiryModule";
import { useState } from "react";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import styles from "../../styles/PageLayout.module.css";
import React from 'react';

const NewInquiry = () => {
    const isMobile = useMobile();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <UserHeaderMobile />
            ) : (
                <DesktopHeader
                    role={"user"}
                    handleNotificationPanelToggle={() => setIsNotificationPanelOpen(!isNotificationPanelOpen)}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={`${styles.rightSectionContainer} ${isNotificationPanelOpen ? styles.blurred : ""}`}>
                    <NewInquiryModule
                        isEdit={false}
                        isMobile={isMobile}
                    />
                </div>
                {isNotificationPanelOpen &&
                    <div className={styles.notificationContainer}>
                        <NotificationSection isOpen={isNotificationPanelOpen} />
                    </div>
                }
            </div>
        </div>
    );
};

export default NewInquiry;