import styles from "../../styles/dashboard/SellersSection.module.css"
import common_styles from "../../styles/dashboard/common.module.css";
import {IoPieChartOutline} from "@react-icons/all-files/io5/IoPieChartOutline";
import ReactLoading from "react-loading";
import React, {useEffect, useRef, useState} from "react";
import useSpendingByStorePieChart from "../../hooks/dashboard/sellers_section/useSpendingByStorePieChart";
import useStoresRankingTable from "../../hooks/dashboard/overview_section/useStoresRankingTable";
import {FaTable} from "@react-icons/all-files/fa/FaTable";
import RankingTable from "../../components/RankingTable/RankingTable";
import useStoreByMainCategoryPieChart
    from "../../hooks/dashboard/sellers_section/useStoreByMainCategoryPieChart";
import CreatableSelect from "react-select/creatable";
import {getAllStores} from "../../APIs/store";
import {getMainCategories} from "../../APIs/auditor_dashboard";

const SellersSection = ({filters}) => {

    // Spending distribution by store
    const [spendingByStoreLoading, setSpendingByStoreLoading] = useState(false);
    const spendingByProductPieChartRef = useRef(null);
    const spendingByProductPieInstanceRef = useRef(null);
    useSpendingByStorePieChart(spendingByProductPieChartRef, spendingByProductPieInstanceRef, filters, setSpendingByStoreLoading);

    // stores ranking table
    const [topStoresLoading, setTopStoresLoading] = useState(false);
    const {
        storesRankingHeaders,
        storesRankingData
    } = useStoresRankingTable(filters, setTopStoresLoading);

    // store spending distribution by product main category
    const [selectedMainCategory, setSelectedMainCategory] = useState(null);
    const [storeByMainCategoryLoading, setStoreByMainCategoryLoading] = useState(false);
    const storeByMainCategoryPieChartRef = useRef(null);
    const storeByMainCategoryPieInstanceRef = useRef(null);
    useStoreByMainCategoryPieChart(storeByMainCategoryPieChartRef, storeByMainCategoryPieInstanceRef,
        filters, selectedMainCategory, setStoreByMainCategoryLoading);


    // stores
    const [mainCategories, setMainCategories] = useState([]);
    useEffect(() => {
        const fetchMainCategories = async () => {
            try {
                const response = await getMainCategories(filters);
                setMainCategories(response.data.main_categories);
            } catch (error) {
                console.error('Error fetching main_categories:', error);
            }
        }

        fetchMainCategories();
    }, []);

    return (
        <div className={styles.sellers_section_container}>
            <div className={styles.sellers_section_first_col}>
                <div className={styles.pie_chart}>
                    <div className={common_styles.section_header_container}>
                        <IoPieChartOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            توزیع میزان خرید از هر فروشنده
                        </span>
                    </div>
                    {spendingByStoreLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={styles.pie_chart_body}>
                            <canvas ref={spendingByProductPieChartRef}></canvas>
                        </div>
                    }
                </div>
            </div>
            <div className={styles.sellers_section_second_col}>
                <div className={styles.ranking_table_container}>
                    <div className={common_styles.section_header_container}>
                        <FaTable className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            فروشندگان برتر
                        </span>
                    </div>
                    {topStoresLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        <div className={common_styles.ranking_table}>
                            <RankingTable
                                headers={storesRankingHeaders}
                                data={storesRankingData}
                                tableTitle={"فروشندگان با بیشترین مجموع خرید"}
                            />
                        </div>
                    }
                </div>
                <div className={styles.smaller_pie_chart}>
                    <div className={common_styles.section_header_container}>
                        <IoPieChartOutline className={common_styles.section_icon}/>
                        <span className={common_styles.section_header}>
                            توزیع گروه‌های کالایی خریداری شده از فروشندگان
                        </span>
                        <div className={styles.seller_select_container}>
                            <span className={common_styles.filter_select_subject}>گروه کالا:</span>
                            <div className={common_styles.filter_select_container}>
                                <CreatableSelect
                                    className={common_styles.filters_select}
                                    value={{
                                        label: selectedMainCategory === null ? "انتخاب کنید" : selectedMainCategory,
                                        value: selectedMainCategory === null ? "انتخاب کنید" : selectedMainCategory
                                    }}
                                    onChange={(selectedOption) => setSelectedMainCategory(selectedOption ? selectedOption.value : '')}
                                    options={mainCategories.map((main_category) => ({
                                        label: main_category,
                                        value: main_category,
                                    }))}
                                    isClearable={false}
                                />
                            </div>
                        </div>
                    </div>

                    {storeByMainCategoryLoading ?
                        <div className={common_styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                          height='50px'/>
                        </div>
                        :
                        selectedMainCategory === null ?
                            <span style={{alignSelf: 'center', paddingTop: '4vw', color: 'var(--primary-dark-color)'}}>برای
                                مشاهده این قسمت لازم است ابتدا یک گروه کالا را انتخاب کنید.</span>
                            :
                            <div className={styles.horizontal_pie_chart_body}>
                                <canvas ref={storeByMainCategoryPieChartRef}></canvas>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default SellersSection;