import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

const AutoGrowInput = ({placeholder, styleClassName, onInputChange, value, disabled = false}) => {
    const inputRef = useRef(null);

    const handleInput = () => {
        const input = inputRef.current;
        input.style.height = 'auto';
        input.style.height = `${input.scrollHeight}px`;
        onInputChange(input.value);
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            // inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [value]);

    return (
        <textarea
            ref={inputRef}
            placeholder={placeholder}
            className={styleClassName}
            onInput={handleInput}
            value={value}
            onChange={handleInput}
            disabled={disabled}
        />
    );
};

AutoGrowInput.propTypes = {
    placeholder: PropTypes.string,
    onInputChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool
};

export default AutoGrowInput;