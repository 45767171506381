import React, {useEffect, useMemo, useState} from "react";
import stylesMobile from "../../styles/user/mobile/UserInquiries.module.css";
import stylesDesktop from "../../styles/user/desktop/UserInquiries.module.css";
import {useNavigate} from "react-router-dom";
import NoInquiryIcon from "../../assets/images/NoInquiryIcon.svg";
import LoadingComponent from "../../components/Loading/Loading";
import convertDateToShamsi from "../../utils/functions"
import StatusNavbar from "../../components/StatusNavbar/StatusNavbar";
import {
    getInquiryCounts,
    getUnopenedInquiryEventsCountBatch,
    getUserInquiries,
    openInquiryEvents
} from "../../APIs/inquiry";
import InquiryUpdates from "../../components/InquiryUpdatesIcon/InquiryUpdates";
import {getUnopenedInquiryEventsCount} from "../../APIs/inquiry";
import Pagination from "../../components/Pagination/Pagination";

const UserInquiriesModule = ({isMobile}) => {
    const styles = isMobile ? stylesMobile : stylesDesktop;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    // State variables fetched in useEffect
    const [inquiries, setInquiries] = useState([]);
    const [inquiryCounts, setInquiryCounts] = useState({});

    const [inquiryStatus, setInquiryStatus] = useState(0);
    const [sortingOption, setSortingOption] = useState(1);

    const [updateCounts, setUpdateCounts] = useState({});
    const MemoizedInquiryUpdates = React.memo(InquiryUpdates);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [inquiriesPerPage] = useState(16);
    const [totalInquiries, setTotalInquiries] = useState(0);
    const indexOfLastInquiry = currentPage * inquiriesPerPage;
    const indexOfFirstInquiry = indexOfLastInquiry - inquiriesPerPage;
    const totalPages = Math.ceil(totalInquiries / inquiriesPerPage);

    // TODO(Low): inquiry statuses should be fetched from back-end
    const inquiryStatuses = {
        Statuses: [
            {id: 0, status: "کارتابل من"},
            {id: 1, status: "در انتظار پردازش"},
            {id: 2, status: "در حال بررسی"},
            {id: 3, status: "بررسی شده"},
            {id: 4, status: "خاتمه یافته"},
            {id: 5, status: "لغو شده"}
        ]
    };

    const sortingOptionsForNotCheckedInquiries = {
        Options: [
            {id: 1, kind: "قدیمی‌ترین (تاریخ ثبت درخواست)"},
            {id: 2, kind: "جدیدترین (تاریخ ثبت درخواست)"},
        ]
    }

    // When the selected option is -1, all inquiries will be fetched because getUserInquiries(-1) does so.
    useEffect(() => {
        const fetchInquiries = async () => {
            setLoading(true);
            try {
                const inquiriesData = await getUserInquiries(inquiryStatus, currentPage, inquiriesPerPage,
                    "submitted_date", sortingOption === 1 ? "asc" : "dsc");
                setInquiries(inquiriesData.data.inquiries);
                setTotalInquiries(inquiriesData.data.total_inquiries_count);

                const inquiryIds = inquiriesData.data.inquiries.map(inquiry => inquiry.id);
                const {data: counts} = await getUnopenedInquiryEventsCountBatch(inquiryIds);
                const countsData = {};
                counts.forEach(({inquiry_id, count}) => {
                    countsData[inquiry_id] = count;
                });
                setUpdateCounts(countsData);

                const {data: {inquiry_counts_by_status}} = await getInquiryCounts();
                setInquiryCounts(inquiry_counts_by_status);
            } catch (error) {
                console.error('Error fetching user inquiries or receipts:', error);
                // Handle error gracefully
                setInquiries([]);
                setUpdateCounts({});
            } finally {
                setLoading(false);
            }
        };

        fetchInquiries();
    }, [inquiryStatus, sortingOption, currentPage, inquiriesPerPage]);

    const handleChangeInquiryStatus = (status) => {
        setInquiryStatus(status);
    };

    // Caches the rendering of inquiry items, recalculating only when the inquiries array changes.
    const renderInquiriesItems = useMemo(() => {
        if (inquiries.length === 0) {
            return <div className={styles.EmptyInquiriesContainer}>
                <img src={NoInquiryIcon} className={styles.EmptyInquiries} alt={"No Inquiries!"}/>
                <span className={styles.EmptyInquiriesMessage}>
                    {inquiryStatus === 0 ?
                        "درخواستی در سیستم ثبت نشده است." :
                        `درخواستی با وضعیت "${inquiryStatuses.Statuses[inquiryStatus].status}" در سیستم ثبت نشده است.`
                    }
                </span>
            </div>
        } else {
            return inquiries.map((inquiry, index) => (
                <div className={styles.InquiryBox} key={index} onClick={async () => {
                    inquiry.status === 1 ? navigate(`/user/edit-inquiry/${inquiry.id}`) : navigate(`/user/inquiry/${inquiry.id}`);
                    await openInquiryEvents(inquiry.id);
                }}>
                    <div className={styles.ContentContainer}>
                        <div className={styles.FirstRow}>
                            <span
                                className={styles.NameText}>{inquiry.subject}
                            </span>

                            {
                                inquiry.is_returned &&
                                <span className={styles.returnedText}>
                                    مرجوعی
                                </span>
                            }
                        </div>
                        <div className={styles.InfoSection}>
                            <div className={styles.RightSection}>
                                <span className={styles.DateText}>
                                    شناسه درخواست:&nbsp;
                                    <strong>{inquiry.name}</strong>
                                </span>
                                <div className={styles.SecondRow}>
                                    <span className={styles.DateText}>
                                        تاریخ ثبت:&nbsp;
                                        <strong>{convertDateToShamsi(inquiry.submitted_date)}</strong>
                                    </span>
                                </div>

                                {inquiry.checked_date &&
                                    <div className={styles.ThirdRow}>
                                        <span className={styles.DateText}>
                                            تاریخ بررسی:&nbsp;
                                            <strong>{convertDateToShamsi(inquiry.checked_date)}</strong>
                                        </span>
                                    </div>
                                }
                            </div>
                            <MemoizedInquiryUpdates inquiry_id={inquiry.id} count={updateCounts[inquiry.id] || 0}/>
                        </div>
                    </div>
                </div>
            ));
        }
    }, [inquiryStatus, sortingOption, inquiries]);

    return (
        <div
            className={styles.UserInquiriesContainer}>
            <span className={styles.InquiriesHeading}>درخواست‌ها</span>
            {isMobile ?
                (
                    <div className={styles.InquiriesStatusesContainer}>
                        <select
                            className={styles.InquiryDropdown}
                            value={inquiryStatus}
                            onChange={(event) => setInquiryStatus(parseInt(event.target.value))}
                        >
                            {inquiryStatuses.Statuses.map((status, index) => {
                                const count = (
                                    index === 0 ? inquiryCounts['Cartable'] :
                                        index === 1 ? inquiryCounts['Pending'] :
                                            index === 2 ? inquiryCounts['Checking'] :
                                                index === 3 ? inquiryCounts['Checked'] :
                                                    index === 4 ? inquiryCounts['Approved'] :
                                                        index === 5 ? inquiryCounts['Cancelled'] :
                                                            0
                                );

                                return (
                                    <option value={status.id} key={status.id}>
                                        {status.status}
                                        &nbsp;({count} درخواست)
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )
                :
                (
                    <StatusNavbar inquiryStatus={inquiryStatus} handleInquirySelect={handleChangeInquiryStatus}
                                  isAuditor={false} inquiryCounts={inquiryCounts}/>
                )
            }

            <div className={styles.SortingAndShowingContainer}>
                <div className={styles.SortingContainer}>
                    <span className={styles.BodyText}>مرتب‌سازی: </span>
                    <select className={styles.InquiryDropdown} value={sortingOption}
                            onChange={(e) => setSortingOption(parseInt(e.target.value))}>
                        {
                            sortingOptionsForNotCheckedInquiries.Options.map((option) => (
                                <option value={option.id} key={option.id}>
                                    {option.kind}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            {loading ?
                <LoadingComponent isMobile={isMobile}/>
                :
                <div className={styles.InquiryItemsContainer}>
                    {renderInquiriesItems}
                </div>
            }
            {
                (!loading && inquiries.length > 0 && (
                    <div className={styles.PaginationWrapper}>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={setCurrentPage}
                        />
                        {!isMobile &&
                            <div className={styles.ItemCount}>
                                نمایش
                                موارد {indexOfFirstInquiry + 1} تا {Math.min(indexOfLastInquiry, totalInquiries)} از {totalInquiries} مورد
                            </div>
                        }
                    </div>
                ))
            }
            <button
                onClick={() => navigate(`/user/new-inquiry`)}
                className={styles.CreateNewInquiryButton}
            >
                درخواست جدید
            </button>
        </div>
    );
};

export default UserInquiriesModule;
